import React from 'react';
import { css } from 'emotion';
import { goBack, push } from 'connected-react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AppHeader from '../app-header';
import expandIcon from '../../assets/expand-icon.svg';
import historyIcon from '../../assets/history-icon.svg';
import { logout } from '../../modules/user';
import { getTicketsHistory } from '../../modules/ticket';
import packageJson from '../../../package.json';
function Settings(props) {
  const displayHistory = () => {
    props.getTicketsHistory();
    props.goToActionHistory();
  };
  return (
    <div className={divStyle}>
      <AppHeader title="Settings" backIcon handleBackButton={props.prevPage} />
      <div>
        <div className={headerStyle}> Account </div>
        <div className={subContainer} onClick={displayHistory}>
          <div className={wrapConatainer}>
            <div>
              <img className={IconStyle} src={historyIcon} alt="history" />
            </div>
            <div className={itemStyle}> Action History </div>
          </div>
          <div className={expandIconStyle}>
            <img src={expandIcon} alt=">" />
          </div>
        </div>
        <hr className={hrStyle} />
        <div className={versionStyle}>Version {packageJson.version}</div>
        <div className={logoutContainer} onClick={() => props.logout(false)}>
          <div className={logoutStyle}>Logout</div>
        </div>
      </div>
    </div>
  );
}
const divStyle = css({
  height: '100vh',
  backgroundColor: '#fafbfc'
});
const wrapConatainer = css({
  display: 'flex',
  justifyContent: 'flex-start'
});
const headerStyle = css({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '16px',
  height: '56px',
  backgroundColor: '#f4f5f7',
  fontSize: '16px',
  fontFamily: 'sans-serif',
  fontStyle: 'normal',
  color: '#212121'
});
const itemStyle = css({
  marginLeft: '28px',
  color: '#212121',
  fontSize: '16px',
  fontFamily: 'sans-serif',
  fontStyle: 'normal'
});

const hrStyle = css({
  margin: 0,
  border: 'none',
  borderBottom: 'solid 1px #dbdbdb'
});
const subContainer = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '80px',
  backgroundColor: '#ffffff'
});
const expandIconStyle = css({
  marginRight: '16px'
});
const IconStyle = css({
  marginLeft: '16px'
});
const logoutContainer = css({
  position: 'fixed',
  bottom: 'env(safe-area-inset-bottom)',
  width: '100%',
  height: '64px',
  backgroundColor: '#ebecf0'
});
const logoutStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#757575',
  fontSize: '16px',
  fontFamily: 'sans-serif',
  fontWeight: 500,
  fontStyle: 'normal',
  width: '100%',
  height: '64px',
  textTransform: 'uppercase'
});
const versionStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  bottom: 'calc(80px + env(safe-area-inset-bottom))',
  color: '#bdbdbd',
  fontSize: '14px',
  fontFamily: 'sans-serif',
  fontStyle: 'normal',
  width: '100%',
  height: '21px',
  textTransform: 'capitalize'
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      prevPage: () => goBack(),
      goToActionHistory: () => push('/actionHistory'),
      logout,
      getTicketsHistory
    },
    dispatch
  );
export default connect(
  null,
  mapDispatchToProps
)(Settings);
