export const TOKEN = 'pk.eyJ1IjoibWFwYm94ZGV2cyIsImEiOiJja2Z0ZzhtemkwbjF0MnN0Ym5sYWZjeDhhIn0.Q8ZWeEgUGaaN7rjrQ7vYUw';
// export const OLD_TOKEN = 'pk.eyJ1IjoiYWFydXNoaW1pc2hyYSIsImEiOiJjaWZ4dXd4ODk0aGhudjhseTZsazB5dHpuIn0.gAFSSGC1mrB9YWIOQOuq1A';
export const FETCH_SITES_REQUESTED = 'FETCH_SITES_REQUESTED';
export const FETCH_SITES_RECEIVED = 'FETCH_SITES_RECEIVED';
export const LAYER_4X_ZOOM_RANGE = [17, 23];
export const LAYER_3X_ZOOM_RANGE = [15, 17];
export const RECT_SPACE_STYLE = 'rect-space-style';
export const RECT_SPACE_OUTLINE_STYLE = 'rect-space-outline-style';
export const RECT_TIMED_VIOLATION_STYLE = 'rect-timed-violation-style';
export const RECT_PAID_VIOLATION_STYLE = 'rect-paid-violation-style';
export const RECT_PARKING_VIOLATION_STYLE = 'rect-no-parking-violation-style';
export const RECT_MULTIPLE_VIOLATION_STYLE =
  'rect-multiple-parking-violation-style';
export const RECT_SELECTED_SPACE_STYLE = 'rect-selected-space-style';
export const RECT_SPACE_NAME_TEXT_STYLE = 'rect-space-name-text-style';
export const FETCH_VIOLATIONS_REQUESTED = 'FETCH_VIOLATIONS_REQUESTED';
export const FETCH_VIOLATIONS_RECEIVED = 'FETCH_VIOLATIONS_RECEIVED';
export const FETCH_VIOLATIONS_DETAILS_REQUESTED =
  'FETCH_VIOLATIONS_DETAILS_REQUESTED';
export const FETCH_VIOLATIONS_DETAILS_RECEIVED =
  'FETCH_VIOLATIONS_DETAILS_RECEIVED';
export const IGNORE_VIOLATIONS_REQUESTED = 'IGNORE_VIOLATIONS_REQUESTED';
export const IGNORE_VIOLATIONS_RECEIVED = 'IGNORE_VIOLATIONS_RECEIVED';
export const RESET_IGNORE_VIOLATION_STATUS = 'RESET_IGNORE_VIOLATION_STATUS';
export const SITES_GEOMETRY_CACHED = 'SITES_GEOMETRY_CACHED';
export const RESET_STATUS = 'RESET_STATUS';
export const FETCH_1X_VIOLATIONS_REQUESTED = 'FETCH_1X_VIOLATIONS_REQUESTED';
export const FETCH_1X_VIOLATIONS_RECEIVED = 'FETCH_1X_VIOLATIONS_RECEIVED';
export const FETCH_4X_VIOLATIONS_REQUESTED = 'FETCH_4X_VIOLATIONS_REQUESTED';
export const FETCH_4X_VIOLATIONS_RECEIVED = 'FETCH_4X_VIOLATIONS_RECEIVED';
export const MAP_BOUNDS_PADDING_PERCENT = 20;
export const SET_SPACE_DETAILS_HEIGHT = 'SET_SPACE_DETAILS_HEIGHT';
export const NETWORK_ERROR = 'NETWORK_ERROR';
