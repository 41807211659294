import axios from 'axios';
import { API_ROOT } from '../constants/general';
import { getToken, isTokenValid } from './user';
import { getStore } from '../index';
import { logout } from '../modules/user';

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    let error = new Error(response);
    throw error;
  }
}

function onAPIError(error) {
  if (
    error.response &&
    (error.response.status === 401 || error.response.status === 403)
  ) {
    getStore().dispatch(logout(false));
  }
  throw error;
}

function onAPIResponse(response) {
  return response.data;
}

export const getRequest = async (url, user) => {
  let headers = {};
  if (user) {
    if (isTokenValid(user)) {
      headers['XauthToken'] = getToken(user);
    } else {
      getStore().dispatch(logout(false));
    }
  }

  return await axios
    .get(API_ROOT + url, { headers })
    .then(checkStatus)
    .then(onAPIResponse)
    .catch(onAPIError);
};

export const getEnforceRequest = async (url, user) => {
  let headers = {};
  if (user) {
    if (isTokenValid(user)) {
      headers['XauthToken'] = getToken(user);
    } else {
      getStore().dispatch(logout(false));
    }
  }

  return await axios
    .get(API_ROOT + url, { headers })
    .then(checkStatus)
    .then(onAPIResponse)
    .catch(onAPIError);
};

export const postRequest = async (url, data, user) => {
  let headers = {
    'Content-Type': 'application/json; charset=utf-8'
  };
  if (user) {
    if (isTokenValid(user)) {
      headers['XauthToken'] = getToken(user);
    } else {
      getStore().dispatch(logout(false));
    }
  }
  return await axios
    .post(API_ROOT + url, data, { headers })
    .then(checkStatus)
    .then(onAPIResponse)
    .catch(onAPIError);
};

export const postEnforceRequest = async (url, data, user) => {
  let headers = {
    'Content-Type': 'application/json; charset=utf-8'
  };
  if (user) {
    if (isTokenValid(user)) {
      headers['XauthToken'] = getToken(user);
    } else {
      getStore().dispatch(logout(false));
    }
  }
  return await axios
    .post(API_ROOT + url, data, { headers })
    .then(checkStatus)
    .then(onAPIResponse)
    .catch(onAPIError);
};

export const postRequestWithoutUser = async (url, data) => {
  // let headers = {
  //   'Content-Type': 'application/json; charset=utf-8'
  // };
  let headers = {
    'Content-Type': 'application/json'
  };
  return await axios
    .post(API_ROOT + url, data, { headers })
    .then(checkStatus)
    .then(onAPIResponse)
    .catch(onAPIError);
};

export function constructSitesAPIUrl(bounds) {
  const north = Math.max(bounds[0], bounds[2]);
  const south = Math.min(bounds[0], bounds[2]);
  const east = Math.max(bounds[1], bounds[3]);
  const west = Math.min(bounds[1], bounds[3]);

  let url = 'sites';
  url += '?top_lat=' + north;
  url += '&bottom_lat=' + south;
  url += '&left_lon=' + west;
  url += '&right_lon=' + east;
  return url;
}
