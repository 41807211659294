export const ADD_VIOLATION = 'ADD_VIOLATION';
export const INITIALIZED_TICKET = 'INITIALIZED_TICKET';
export const VERIFIED_VEHICLE = 'VERIFIED_VEHICLE';
export const VEHICLE_DETAILS = 'VEHICLE_DETAILS';
export const UPLOAD_IMAGE_REQUESTED = 'UPLOAD_IMAGE_REQUESTED';
export const UPLOAD_IMAGE_RECIEVED = 'UPLOAD_IMAGE_RECIEVED';
export const IMAGE_UPLOAD_FAILURE = 'IMAGE_UPLOAD_FAILURE';
export const GENERATE_TICKET_REQUESTED = 'GENERATE_TICKET_REQUESTED';
export const GENERATE_TICKET_RECIEVED = 'GENERATE_TICKET_RECIEVED';
export const GENERATE_TICKET_FAILURE = 'GENERATE_TICKET_FAILURE';
export const SAVE_END_TIME = 'SAVE_END_TIME';
export const ENTER_LPN = 'Please Enter Licence Plate Number';
export const INVALID_VIN = 'INVALID_VIN';
export const ENTER_VALID_VIN = 'VIN should be 17 characters';
export const TICKET_HISTORY_REQUESTED = 'TICKET_HISTORY_REQUESTED';
export const TICKET_HISTORY_RECIEVED = 'TICKET_HISTORY_RECIEVED';
export const TICKET_HISTORY_FAILURE = 'TICKET_HISTORY_FAILURE;';
export const NO_PARKING = 'No Parking';
export const METER_EXPIRED = 'Meter Expired';
export const TIME_EXPIRED = 'Time Expired';
export const RESET_HISTORY = 'RESET_HISTORY';
