/*global Camera*/
import React, { useState, useEffect } from 'react';
import { css, keyframes } from 'emotion';
import AppHeader from '../app-header';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { uploadImage } from '../../modules/ticket';
import { isInAppEnv } from '../../utils/mobile';
import classnames from 'classnames';
import Button from '../button';

import {
  UPLOAD_IMAGE_RECIEVED,
  UPLOAD_IMAGE_REQUESTED
} from '../../constants/ticket';
function ViolationImage(props) {
  const [displayImg, setDisplayImage] = useState(false);
  const [disableCreateTicketBtn, setDisableCreateTicketBtn] = useState(false);

  const [imgSrc, setImgSrc] = useState('');
  const handleTakePhoto = () => {
    if (isInAppEnv()) {
      let opt = {
        quality: 80,
        destinationType: Camera.DestinationType.DATA_URL,
        sourceType: Camera.PictureSourceType.CAMERA,
        mediaType: Camera.MediaType.PICTURE,
        cameraDirection: Camera.Direction.BACK,
        correctOrientation: true
      };
      navigator.camera.getPicture(cameraSuccess, cameraError, opt);
    } else {
      console.log('Will invoke the camera in mobile app environment.');
    }
  };
  useEffect(() => {
    props.ticket.photoStatus === UPLOAD_IMAGE_REQUESTED
      ? setDisableCreateTicketBtn(true)
      : setDisableCreateTicketBtn(false);

    if (
      imgSrc.length !== 0 &&
      props.ticket.photoStatus === UPLOAD_IMAGE_RECIEVED
    ) {
      props.goToConfirmDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ticket.photoStatus]);

  const cameraSuccess = imageData => {
    setImgSrc(imageData);
    setDisplayImage(true);
    var smallImage = document.getElementById('smallImage');
    smallImage.src = 'data:image/jpeg;base64,' + imageData;
  };
  const cameraError = () => {
    console.log('Camera error.');
  };
  const handleNextButton = () => {
    if (imgSrc.length !== 0) {
      props.uploadImage(imgSrc);
    } else {
      props.goToConfirmDetails();
    }
  };
  const handleSettingsButton = () => {
    props.goToSettingsPage();
  };
  return (
    <div className={divStyle}>
      <AppHeader
        title="Create Ticket"
        settingsIcon
        backIcon
        handleBackButton={props.prevPage}
        handleSettingsButton={handleSettingsButton}
      />
      <div>
        <div className={headerStyle}>
          <div className={progressBarStyle}></div>{' '}
        </div>
        <div className={container}>
          <div className={subContainer}>
            <div className={heading}> Violation Image </div>
            <div className={subHeading}> Click a picture of the violation</div>
            {displayImg ? (
              <div className={imgDiv}>
                <img
                  id="smallImage"
                  src=""
                  className={imgstyle}
                  alt="vehicle"
                />
              </div>
            ) : (
              <div className={picBtnStyle} onClick={handleTakePhoto}>
                {' '}
                TAKE A PICTURE{' '}
              </div>
            )}
          </div>
          {displayImg ? (
            <Button
              btnClassName={retakePicButtonStyle}
              btnTextClassName={retakePicButtonTextStyle}
              buttonClickCallback={() => handleTakePhoto()}
              text="RETAKE PICTURE"
            />
          ) : null}
          <button
            disabled={
              props.ticket.photoStatus !== null &&
              props.ticket.photoStatus !== UPLOAD_IMAGE_RECIEVED
            }
            className={classnames({
              [`${nextBtnContainer}`]: true,
              [`${enabledNextBtnConatainer}`]: !disableCreateTicketBtn
            })}
            onClick={handleNextButton}
          >
            {disableCreateTicketBtn ? (
              <div>
                Uploading<span className={loaderDotStyle}>.</span>
                <span className={loaderDotStyle}>.</span>
                <span className={loaderDotStyle}>.</span>
              </div>
            ) : (
              <span>NEXT</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
const divStyle = css({
  height: '100vh',
  backgroundColor: '#fafbfc'
});
const headerStyle = css({
  height: '8px',
  backgroundColor: '#ffffff'
});
const progressBarStyle = css({
  backgroundColor: '#34b37e',
  width: '76%',
  height: '8px',
  borderRadius: '0px 5px 5px 0px'
});
const container = css({
  margin: '16px 8px 0 8px'
});

const dotsKeyframes = keyframes`
  50% { color: transparent }
`;

const loaderDotStyle = css({
  animation: `1s ${dotsKeyframes} infinite`,
  ':nth-child(2)': {
    animationDelay: '250ms'
  },
  ':nth-child(3)': {
    animationDelay: '500ms'
  }
});

const subContainer = css({
  backgroundColor: '#ffffff',
  fontFamily: 'sans-serif',
  fontStyle: 'normal',
  border: '1px solid #ebecf0',
  borderRadius: '1px',
  boxShadow: '2px 2px 2px 2px #23000000'
});
const heading = css({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '16px',
  fontSize: '18px',
  color: '#212121',
  height: '50px'
});
const subHeading = css({
  margin: '0 0 24px 16px',
  fontSize: '14px',
  color: '#757575',
  height: '21px'
});
const picBtnStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'sans-serif',
  fontStyle: 'normal',
  fontSize: '16px',
  color: '#0151cb',
  height: '56px',
  margin: '0 16px 16px 16px',
  borderRadius: '2px',
  boxShadow: '0px 1px 3px #bdbdbd'
});
const nextBtnContainer = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#c1c7d0',
  color: '#ebecf0',
  position: 'fixed',
  bottom: '0px',
  left: '0px',
  fontSize: '16px',
  margin: '16px',
  height: '56px',
  width: 'calc(100% - 32px)',
  borderRadius: '2px',
  boxShadow: '0px 1px 3px #dbdbdb',
  '&:focus': {
    outline: 'none'
  }
});
const retakePicButtonStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '56px',
  width: 'calc(100% - 32px)',
  margin: '16px',
  color: '#ebecf0',
  position: 'fixed',
  bottom: '66px',
  left: '0px',
  fontSize: '16px',
  borderRadius: '2px',
  boxShadow: '0px 1px 3px #dbdbdb',
  backgroundColor: '#ffffff',
  border: '1px solid #bdbdbd',
  '&:hover:enabled': {
    backgroundColor: '#ffffff',
    color: '#ebecf0'
  },
  '&:active:enabled': {
    backgroundColor: '#ffffff',
    color: '#ebecf0'
  }
});

const retakePicButtonTextStyle = css({
  color: '#0151cb'
});

const enabledNextBtnConatainer = css({
  backgroundColor: '#0152cc',
  color: '#ffffff',
  '&:focus': {
    outline: 'none'
  }
});
const imgDiv = css({
  height: '240px',
  margin: '0 16px 24px 16px'
});
const imgstyle = css({
  width: '100%',
  height: '240px'
});
const mapStateToProps = state => ({
  ticket: state.ticket
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      prevPage: () => goBack(),
      goToConfirmDetails: () => push('/ticketDetails'),
      goToSettingsPage: () => push('/settings'),
      uploadImage
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViolationImage);
