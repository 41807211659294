import React, { useState, useEffect } from 'react';
import { css } from 'emotion';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Login from './login';
import LoginError from './loginerror';
import AppHeader from '../app-header';
import { loginWithCreds } from '../../modules/user';
import { USER_LOG_IN_REQESTED, USER_LOG_IN_ERROR } from '../../constants/user';
import { FIELD_EMPTY, ENTER_ALL_FIELDS } from '../../constants/general';

function LoginContainer(props) {
  const onLoginSubmit = (user, pass) => {
    if (!user || !pass) {
      setloginError(FIELD_EMPTY);
    } else {
      props.loginWithCreds(user, pass);
    }
  };

  const [loginStatus, setloginStatus] = useState(null);
  const [loginError, setloginError] = useState(null);

  useEffect(() => {
    setloginStatus(props.user.status === USER_LOG_IN_REQESTED);
    setloginError(props.user.status === USER_LOG_IN_ERROR);
    if (props.user.isLoggedIn) {
      props.goToFieldPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.status, props.user.isLoggedIn]);

  return (
    <div className={loginContainer}>
      <AppHeader title="Login" />
      {loginError ? (
        <LoginError
          content={loginError === FIELD_EMPTY ? ENTER_ALL_FIELDS : ''}
        />
      ) : null}
      <Login
        onSubmit={(user, pass) => onLoginSubmit(user, pass)}
        isLoggedIn={props.user.isLoggedIn}
        loginError={loginError}
        setloginError={setloginError}
        loading={loginStatus}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loginWithCreds,
      goToFieldPage: () => push('/')
    },
    dispatch
  );

const loginContainer = css({
  backgroundColor: '#F8F8F8',
  height: '100vh'
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
