import React, { useState, useEffect } from 'react';
import { css } from 'emotion';
import AppHeader from '../app-header';
import { connect } from 'react-redux';
import whiteTickImage from '../../assets/white-blue-tick.svg';
import { goBack } from 'connected-react-router';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { addViolation } from '../../modules/ticket';
import Button from '../button';
import moment from 'moment-timezone';

import {
  NO_PARKING,
  METER_EXPIRED,
  TIME_EXPIRED
} from '../../constants/ticket';
let title = 'Add Violations';
let discription =
  'Select the violations for the site. You can have multiple selections';
const violationTypes = ['parkingViolation', 'paidViolation', 'timedViolation'];
function AddViolations(props) {
  let ticketDetails = props.ticket.ticketDetails;
  const [checked, setChecked] = useState([false, false, false]);
  useEffect(() => {
    if (props.ticket.ticketDetails.violations !== undefined) {
      setChecked(
        violationTypes.map(violation => {
          if (props.ticket.ticketDetails.violations.indexOf(violation) !== -1) {
            return true;
          }
          return false;
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ticket.ticketStatus]);

  const handleChange = (event, index) => {
    checked[index] = event.target.checked;
    setChecked([...checked]);
  };
  const handleSettingsButton = () => {
    props.goToSettingsPage();
  };
  const handleNextButton = () => {
    let violations = [];
    if (checked[0]) {
      violations.push({
        type: 0,
        start_time: ticketDetails.since['parking_violation']
          ? ticketDetails.since['parking_violation']
          : moment
              .tz(ticketDetails.geojson.properties['time-zone'])
              .format('YYYY-MM-DD HH:mm:ss')
      });
    }
    if (checked[1]) {
      violations.push({
        type: 1,
        start_time: ticketDetails.since['paid_violation']
          ? ticketDetails.since['paid_violation']
          : moment
              .tz(ticketDetails.geojson.properties['time-zone'])
              .format('YYYY-MM-DD HH:mm:ss')
      });
    }
    if (checked[2]) {
      violations.push({
        type: 2,
        start_time: ticketDetails.since['timed_violation']
          ? ticketDetails.since['timed_violation']
          : moment
              .tz(ticketDetails.geojson.properties['time-zone'])
              .format('YYYY-MM-DD HH:mm:ss')
      });
    }
    props.addViolation(violations);
    props.goToVehicleVerification();
  };
  return (
    <div className={divStyle}>
      <AppHeader
        title="Create Ticket"
        settingsIcon
        backIcon
        handleSettingsButton={handleSettingsButton}
        handleBackButton={props.prevPage}
      />
      <div>
        <div className={headerStyle}>
          <div className={firstHalf}></div>{' '}
        </div>
        <div className={container}>
          <div className={subContainer}>
            <div className={heading}> {title} </div>
            <div className={subHeading}> {discription} </div>
            <div className={listStyle}>
              <div>{NO_PARKING}</div>
              <div className={checkbox}>
                <input
                  id={0}
                  type="checkbox"
                  value={' '}
                  onChange={event => handleChange(event, 0)}
                  checked={checked[0]}
                />
                <label htmlFor={0} />
              </div>
            </div>
            <div className={listStyle}>
              <div>{TIME_EXPIRED}</div>
              <div className={checkbox}>
                <input
                  id={2}
                  type="checkbox"
                  value={' '}
                  onChange={event => handleChange(event, 2)}
                  checked={checked[2]}
                />
                <label htmlFor={2} />
              </div>
            </div>
            <div className={listStyle}>
              <div>{METER_EXPIRED}</div>
              <div className={checkbox}>
                <input
                  id={1}
                  type="checkbox"
                  value={' '}
                  onChange={event => handleChange(event, 1)}
                  checked={checked[1]}
                />
                <label htmlFor={1} />
              </div>
            </div>
          </div>
          <Button
            btnClassName={nextBtnContainer}
            text="Next"
            buttonClickCallback={handleNextButton}
          />
        </div>
      </div>
    </div>
  );
}
const divStyle = css({
  height: '100vh',
  backgroundColor: '#fafbfc'
});
const headerStyle = css({
  height: '8px',
  backgroundColor: '#ffffff'
});
const firstHalf = css({
  backgroundColor: '#34b37e',
  width: '19%',
  height: '8px',
  borderRadius: '0px 5px 5px 0px'
});
const container = css({
  margin: '16px 8px 0 8px'
});
const subContainer = css({
  backgroundColor: '#ffffff',
  fontFamily: 'sans-serif',
  fontStyle: 'normal',
  border: '1px solid #ebecf0',
  borderRadius: '1px',
  marginBottom: '86px'
});
const heading = css({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '16px',
  fontSize: '18px',
  color: '#212121',
  height: '50px'
});
const subHeading = css({
  marginLeft: '16px',
  fontSize: '14px',
  color: '#757575',
  height: '42px'
});
const listStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: '16px',
  height: '64px'
});
const checkbox = css({
  display: 'flex',
  width: '18px',
  height: '18px',
  position: 'relative',
  marginRight: '16px',
  '& input[type=checkbox]': {
    visibility: 'hidden'
  },
  '& label': {
    display: 'inline-block',
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '18px',
    height: '18px',
    boxSizing: 'border-box',
    border: 'solid 1px #c1c7d0',
    borderRadius: '2px'
  },
  '& input[type=checkbox]:checked + label': {
    content: `url(${whiteTickImage})`,
    transition: 'all .5s ease',
    border: 0,
    backgroundColor: '#0151cb'
  }
});
const nextBtnContainer = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  bottom: '0px',
  left: '0px',
  margin: '16px',
  height: '56px',
  backgroundColor: '#0152cc',
  width: 'calc(100% - 32px)',
  color: '#ffffff'
});
const mapStateToProps = state => ({
  ticket: state.ticket
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      prevPage: () => goBack(),
      goToVehicleVerification: () => push('/vehicleVerification'),
      goToSettingsPage: () => push('/settings'),
      addViolation
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddViolations);
