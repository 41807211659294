import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';
import React, { useEffect, useState, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { css } from 'emotion';
import Blink from '../react-blink-text/ReactBlinkText';

import 'mapbox-gl/dist/mapbox-gl.css';
import {
	getSitesGeometry,
	resetStatus,
	getSitesViolations,
	getSites4xViolations,
	getSiteViolationDetails,
	resetIgnoreViolationStatus
} from '../../modules/map';
import {
	drawSpaces,
	drawCircles,
	updateMarkers,
	getSiteDetailsHTML
} from './4x-layer';
import {
	TOKEN,
	FETCH_SITES_RECEIVED,
	FETCH_VIOLATIONS_RECEIVED,
	FETCH_4X_VIOLATIONS_RECEIVED,
	IGNORE_VIOLATIONS_RECEIVED,
	RECT_SPACE_STYLE,
	RECT_TIMED_VIOLATION_STYLE,
	RECT_PAID_VIOLATION_STYLE,
	RECT_PARKING_VIOLATION_STYLE,
	RECT_MULTIPLE_VIOLATION_STYLE
} from '../../constants/map';
import AppHeader from '../app-header';
import ParkingSpaceDetails from '../parking-space-details';
import MapLegend from '../map-legend';
import MapLoader from '../map-loader';
import MapControls from '../map-controls';

let markers = {};
let markersOnScreen = {};
let spaceDetailsHeightForMap = 0;

function Map(props) {
	const mapContainer = useRef();
	const map = useRef();
	const [mapLoaded, setMapLoaded] = useState(false);
	const [showLoader, setShowLoader] = useState(true);
	const [circlesSourceAdded, setCirclesSourceAdded] = useState(false);
	const [violations4xCallId, setViolations4xCallId] = useState(null);
	const [violations5xCallId, setViolations5xCallId] = useState(null);
	const [showSpaceDetailsId, setshowSpaceDetailsId] = useState(null);
	const [selectedSpaceId, setSelectedSpaceId] = useState(null);
	const [panMap, setPanMap] = useState(false);
	const [spaceDetailsHeight, setSpaceDetailsHeight] = useState(0);
	const [mapAdjustedForBound, setMapAdjustedForBound] = useState(false);
	const popup = useRef();
	const spaceDetails = useRef();
	const mapStyles = [
		'mapbox://styles/mapboxdevs/clei2f14r000601pkwhzfoetp',
		'mapbox://styles/mapboxdevs/clei7y82m000201tla4kyzw9s',
		'mapbox://styles/mapbox/streets-v12',
		'mapbox://styles/mapbox/satellite-v9'
	];
	const mapStyleIndex = useRef(0);
	const [spacesDrawn, setSpacesDrawn] = useState(false);
	const [changingStyle, setChangingStyle] = useState(false);

	useEffect(() => {
		mapboxgl.accessToken = TOKEN;
		let mapConfig = {
			attributionControl: false,
			container: mapContainer.current,
			style: mapStyles[mapStyleIndex.current],
			minZoom: 5,
			maxZoom: 22,
			zoom: 17.5
		};
		const selectedDeployment = props.deployments.cities.filter(
			city => city.selected
		)[0];
		// let north, south, east, west;
		if (selectedDeployment) {
			// const flatBounds = selectedDeployment.bounds;
			// north = flatBounds[0][0];
			// east = flatBounds[0][1];
			// south = flatBounds[1][0];
			// west = flatBounds[1][1];
			// mapConfig['bounds'] = [west, south, east, north];
			mapConfig['center'] = [
				selectedDeployment.latlngcenter[1],
				selectedDeployment.latlngcenter[0]
			];
		}

		map.current = new mapboxgl.Map(mapConfig);
		map.current.on('load', () => {
			setMapLoaded(true);
		});
		map.current.on('moveend', () => {
			let markersArray = updateMarkers(map.current, markers, markersOnScreen);
			markers = markersArray[0];
			markersOnScreen = markersArray[1];
		});
		map.current.on('click', e => onFeatureClicked(e, props.map));
		map.current.on('zoom', () => {
			if (popup.current) {
				popup.current.remove();
			}
		});

		map.current.on('style.load', () => {
			setChangingStyle(false);
		});

		map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

		return () => {
			map.current.remove();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			showSpaceDetailsId &&
			spaceDetails &&
			spaceDetailsHeightForMap &&
			panMap
		) {
			map.current.panBy([0, spaceDetailsHeightForMap / 2]);
			setPanMap(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [panMap, spaceDetailsHeightForMap, spaceDetailsHeight]);

	useEffect(() => {
		if (props.map.sitesStatus === FETCH_SITES_RECEIVED) {
			if (!violations5xCallId) {
				props.getSitesViolations(props.map.bounds);
				let intervalId = setInterval(() => {
					props.getSitesViolations(props.map.bounds);
				}, 10000);
				setViolations5xCallId(intervalId);
			}
			if (!violations4xCallId) {
				props.getSites4xViolations(props.map.bounds);
				let intervalId = setInterval(() => {
					props.getSites4xViolations(props.map.bounds);
				}, 10000);
				setViolations4xCallId(intervalId);
			}
		}
		return () => {
			clearInterval(violations5xCallId);
			clearInterval(violations4xCallId);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.map.sitesStatus, violations5xCallId, violations4xCallId]);

	useEffect(() => {
		if (mapLoaded) {
			const selectedDeployment = props.deployments.cities.filter(
				city => city.selected
			)[0];
			let north, south, east, west;
			const bounds = selectedDeployment.bounds;
			north = bounds[0][0];
			east = bounds[0][1];
			south = bounds[1][0];
			west = bounds[1][1];

			props.getSitesGeometry([north, east, south, west]);
		}
		return () => props.resetStatus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mapLoaded]);

	useEffect(() => {
		if (props.map.ignoreViolationStatus === IGNORE_VIOLATIONS_RECEIVED) {
			setshowSpaceDetailsId(null);
			props.resetIgnoreViolationStatus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.map.ignoreViolationStatus]);

	useEffect(() => {
		if (
			mapLoaded && !spacesDrawn && !changingStyle &&
			props.map.sitesStatus === FETCH_SITES_RECEIVED &&
			props.map.violationsStatus === FETCH_VIOLATIONS_RECEIVED
		) {
			drawSpaces(props.map.sites, map.current, selectedSpaceId);
			setSpacesDrawn(true);
		} else {
			setSpacesDrawn(false);
		}
		if (
			mapLoaded && !changingStyle &&
			props.map.sitesGroupsViolationsStatus === FETCH_4X_VIOLATIONS_RECEIVED
		) {
			setShowLoader(false);
			drawCircles(props.map.sitesGroupsViolations, map.current);
			setCirclesSourceAdded(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		mapLoaded,
		props.map.sites,
		props.map.sitesGroupsViolations,
		props.map.sitesGroupsViolationsStatus,
		props.map.sitesStatus,
		props.map.violationsStatus,
		selectedSpaceId
	]);

	useEffect(() => {
		if (circlesSourceAdded) {
			let markersArray = updateMarkers(map.current, markers, markersOnScreen);
			markers = markersArray[0];
			markersOnScreen = markersArray[1];
			setCirclesSourceAdded(false);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}
	}, [circlesSourceAdded]);

	useEffect(() => {
		if (props.deployments.cities.filter(city => city.selected).length === 0) {
			props.goToDeploymentsPage();
		}
	});

	useEffect(() => {
		if (
			props.map.sitesGroupsViolationsStatus === FETCH_4X_VIOLATIONS_RECEIVED &&
			!mapAdjustedForBound
		) {
			let { sitesGroupsViolations } = props.map;
			if (sitesGroupsViolations.length > 1) {
				let bounds = sitesGroupsViolations.reduce(function(bounds, group) {
					return bounds.extend(group.center);
				}, new mapboxgl.LngLatBounds(
					sitesGroupsViolations[0].center,
					sitesGroupsViolations[0].center
				));
				map.current.fitBounds(bounds, {
					padding: 30
				});
			}
			setMapAdjustedForBound(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.map.sitesGroupsViolationsStatus]);

	function mapRecenter() {
		if (spaceDetailsHeightForMap) {
			map.current.panBy([0, -(spaceDetailsHeightForMap / 2)]);
			spaceDetailsHeightForMap = 0;
			setSpaceDetailsHeight(0);
			setPanMap(false);
		}
	}

	function onFeatureClicked(e) {
		mapRecenter();
		setshowSpaceDetailsId(null);
		setSelectedSpaceId(null);
		let loadedLayers = [
			RECT_SPACE_STYLE,
			RECT_TIMED_VIOLATION_STYLE,
			RECT_PAID_VIOLATION_STYLE,
			RECT_PARKING_VIOLATION_STYLE,
			RECT_MULTIPLE_VIOLATION_STYLE
		].filter(layerId => {
			return map.current.getLayer(layerId) || false;
		});

		let features = map.current.queryRenderedFeatures(e.point, {
			layers: loadedLayers
		});

		if (!features || features.length === 0) {
			return;
		}

		// Parking Space is clicked
		// Center the map and add a popup in the center with an offset so that the space is visible
		const centerOfSiteClicked = {
			lng: features[0].properties.lon,
			lat: features[0].properties.lat
		};
		map.current.setCenter(centerOfSiteClicked);
		const siteFeature = features[0];
		setSelectedSpaceId(siteFeature.properties.site_id);
		const siteName = siteFeature.properties.name;
		const totalViolations = siteFeature.properties.totalViolations;
		const html = getSiteDetailsHTML(totalViolations, siteName);
		var div = window.document.createElement('div');
		div.style.cssText =
			'display: flex; justify-content: center;align-items: center;';
		div.innerHTML = html;
		div.addEventListener(
			'click',
			onSiteDetailsClicked.bind(this, siteFeature.properties.site_id)
		);
		if (popup.current) {
			popup.current.remove();
		}
		var popupOffsets = {
			bottom: [0, -15]
		};
		popup.current = new mapboxgl.Popup({ offset: popupOffsets })
			.setLngLat(centerOfSiteClicked)
			.setDOMContent(div)
			.addTo(map.current);
	}

	function onSiteDetailsClicked(site_id) {
		props.getSiteViolationDetails(site_id);
		setPanMap(true);
		setshowSpaceDetailsId(site_id);
	}

	function setSpaceDetailsHeightForMap(height) {
		if (height !== spaceDetailsHeightForMap && panMap) {
			spaceDetailsHeightForMap = height;
			setSpaceDetailsHeight(height);
		}
	}

	const handleSettingsButton = () => {
		props.goToSettingsPage();
	};

	const onMapLayersButtonClick = () => {
		mapStyleIndex.current = (mapStyleIndex.current + 1 === mapStyles.length ? 0 : mapStyleIndex.current + 1);
		map.current.setStyle(mapStyles[mapStyleIndex.current], {diff: true});
		props.getSitesViolations(props.map.bounds);
		props.getSites4xViolations(props.map.bounds);
		setChangingStyle(true);
	};

	return (
		<div className={props.className}>
			<AppHeader
				title={
					props.deployments.cities.filter(city => city.selected).length === 1
						? props.deployments.cities.filter(city => city.selected)[0].name
						: ''
				}
				handleBackButton={props.goToDeploymentsPage}
				backIcon
				settingsIcon
				handleSettingsButton={handleSettingsButton}
			/>
			{props.map.networkError && <div className={networkErrorClass}><Blink text='NETWORK ERROR! &nbsp;&nbsp;CHECK YOUR INTERNET CONNECTION!' color='#fcf46a' fontSize='30px'>NETWORK ERROR!</Blink></div>}
			<div id="mapbox-container" className={mapboxClass} ref={mapContainer}/>
			<MapLoader showLoader={showLoader}/>
			<MapLegend/>
			{!changingStyle && <MapControls onMapLayersButtonClick={onMapLayersButtonClick}/>}
			{showSpaceDetailsId ? (
				<ParkingSpaceDetails
					setSpaceDetailsHeight={setSpaceDetailsHeightForMap}
					forwardedRef={spaceDetails}
					siteId={showSpaceDetailsId}
				/>
			) : null}
		</div>
	);
}

const mapboxClass = css({
	height: 'calc(100vh - 60px - env(safe-area-inset-top))'
});

const networkErrorClass = css({
	padding: '10px',
	fontWeight: 'bold',
	textAlign: 'center',
	background: '#ff0000',
	color: '#fcf46a'
})

const mapStateToProps = state => ({
	map: state.map,
	deployments: state.deployments
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			goToDeploymentsPage: () => push('/'),
			goToSettingsPage: () => push('/settings'),
			getSitesGeometry,
			resetStatus,
			getSitesViolations,
			getSites4xViolations,
			getSiteViolationDetails,
			resetIgnoreViolationStatus
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Map);
