import React, { useState } from 'react';
import { css } from 'emotion';
import AppHeader from '../app-header';
import { connect } from 'react-redux';
import TextField from '../textfield';
import { goBack } from 'connected-react-router';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { verifyVehicle } from '../../modules/ticket';
import Button from '../button';
import LoginError from '../login/loginerror';
import { FIELD_EMPTY } from '../../constants/general';
import {
  ENTER_VALID_VIN,
  INVALID_VIN,
  ENTER_LPN
} from '../../constants/ticket';
function VehicleVerification(props) {
  let ticketDetails = props.ticket.ticketDetails;
  const [lpNum, setLpNum] = useState(ticketDetails.licensePlateNumber);
  const [vinNum, setVinNum] = useState(
    ticketDetails.verificationIdentificationNumber
  );
  const [validationError, seValidationError] = useState(null);
  let buttonStyle = validationError ? btnStyle : activeButtonStyle;

  const [errMsg, setErrMsg] = useState(null);
  let inputStyle = errMsg ? invalidInputFieldStyle : inputFieldStyle;
  const handleLPNChange = e => {
    setLpNum(e.target.value);
    seValidationError(false);
  };

  const handleVINChnage = e => {
    const value = e.target.value;
    setVinNum(value);
    setErrMsg(false);
  };

  const handleNextButton = () => {
    const vinNumWithoutSpaces =
      vinNum === undefined ? null : vinNum.replace(/ /g, '');

    if (!lpNum) {
      seValidationError(FIELD_EMPTY);
    } else if (
      vinNumWithoutSpaces !== null &&
      vinNumWithoutSpaces.length < 17 &&
      vinNumWithoutSpaces.length !== 0
    ) {
      setErrMsg(INVALID_VIN);
    } else {
      props.verifyVehicle(lpNum, vinNum);
      props.goToVehicleDetails();
    }
  };
  const handleSettingsButton = () => {
    props.goToSettingsPage();
  };

  return (
    <div className={divStyle}>
      <AppHeader
        title="Create Ticket"
        settingsIcon
        backIcon
        handleBackButton={props.prevPage}
        handleSettingsButton={handleSettingsButton}
      />
      <div>
        <div className={headerStyle}>
          <div className={progressBarStyle}></div>{' '}
        </div>
        {validationError ? (
          <LoginError
            content={validationError === FIELD_EMPTY ? ENTER_LPN : ''}
          />
        ) : null}
        {errMsg ? (
          <LoginError content={errMsg === INVALID_VIN ? ENTER_VALID_VIN : ''} />
        ) : null}
        <div className={container}>
          <div className={subContainer}>
            <div className={heading}> Vehicle Verification Details </div>
            <div className={subHeading}> Enter the details below</div>
            <div className={listStyle}> License Plate Number </div>
            <div className={textFieldStyle}>
              <TextField
                type="text"
                value={lpNum}
                placeholder="Enter licence plate number"
                inputClass={inputFieldStyle}
                onChange={e => handleLPNChange(e)}
              />
            </div>
            <div className={listStyle}>
              Vehicle Identification Number (Optional)
            </div>
            <div className={textFieldStyle}>
              <TextField
                type="text"
                value={vinNum}
                spacing={4}
                placeholder="Enter VIN (optional)"
                inputClass={inputStyle}
                onChange={e => handleVINChnage(e)}
              />
            </div>
          </div>
          <Button
            btnClassName={buttonStyle}
            text="NEXT"
            buttonClickCallback={handleNextButton}
          />
        </div>
      </div>
    </div>
  );
}
const divStyle = css({
  height: '100vh',
  backgroundColor: '#fafbfc'
});
const headerStyle = css({
  height: '8px',
  backgroundColor: '#ffffff'
});
const progressBarStyle = css({
  backgroundColor: '#34b37e',
  width: '38%',
  height: '8px',
  borderRadius: '0px 5px 5px 0px'
});
const container = css({
  margin: '16px 8px 0 8px'
});
const subContainer = css({
  backgroundColor: '#ffffff',
  fontFamily: 'sans-serif',
  fontStyle: 'normal',
  marginBottom: '86px',
  border: '1px solid #ebecf0',
  borderRadius: '1px',
  boxShadow: '2px 2px 2px 2px #23000000'
});
const heading = css({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '16px',
  fontSize: '18px',
  color: '#212121',
  height: '50px'
});
const subHeading = css({
  margin: '0 0 24px 16px',
  fontSize: '14px',
  color: '#757575',
  height: '21px'
});
const listStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '0px 0 8px 16px',
  height: '24px'
});

const textFieldStyle = css({
  height: '56px',
  margin: '0 16px 24px 16px',
  '::-webkit-input-placeholder': {
    color: '#bdbdbd'
  }
});

const invalidInputFieldStyle = css({
  color: '#000000',
  fontSize: '14px',
  fontFamily: 'sans-serif',
  fontStyle: 'normal',
  border: '1px solid #ff5630',
  borderRadius: '1px'
});
const inputFieldStyle = css({
  color: '#000000',
  fontSize: '14px',
  fontFamily: 'sans-serif',
  fontStyle: 'normal'
});
const activeButtonStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  bottom: '0px',
  left: '0px',
  margin: '16px',
  height: '56px',
  width: 'calc(100% - 32px)',
  borderRadius: '2px',
  boxShadow: '0px 1px 3px #dbdbdb'
});
const btnStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  bottom: '0px',
  left: '0px',
  margin: '16px',
  height: '56px',
  width: 'calc(100% - 32px)',
  borderRadius: '2px',
  boxShadow: '0px 1px 3px #dbdbdb',
  backgroundColor: '#dbdbdb'
});
const mapStateToProps = state => ({
  ticket: state.ticket
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      prevPage: () => goBack(),
      goToVehicleDetails: () => push('/vehicleDetails'),
      goToSettingsPage: () => push('/settings'),
      verifyVehicle
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleVerification);
