import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/app';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import * as serviceWorker from './serviceWorker';
import configureStore, { history } from './store';
import { USER_LOGGED_IN } from './constants/user';

let store = configureStore();
const user = window.localStorage.getItem('user');

if (user) {
  store.dispatch({ type: USER_LOGGED_IN, payload: JSON.parse(user) });
}
export const getStore = () => store;

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
