import feature from 'turf-feature';
import centroid from '@turf/centroid';
import {
	RECT_SPACE_STYLE,
	RECT_TIMED_VIOLATION_STYLE,
	RECT_PAID_VIOLATION_STYLE,
	RECT_PARKING_VIOLATION_STYLE,
	RECT_MULTIPLE_VIOLATION_STYLE,
	RECT_SELECTED_SPACE_STYLE,
	RECT_SPACE_OUTLINE_STYLE,
	RECT_SPACE_NAME_TEXT_STYLE
} from '../../constants/map';

export function getFeatures(sites, selectedSiteId) {
  let siteIds = Object.keys(sites);
  return siteIds.map(siteId => {
    let siteGeojson = sites[siteId].geojson;
    let site = sites[siteId];
    let rectFeature = feature(siteGeojson.geometry);
    let center = centroid(rectFeature).geometry.coordinates;
    if (siteGeojson) {
      rectFeature.properties = {
        ...siteGeojson.properties,
        occupancy: site.occupancy,
        status: site.status,
        street1: site.street1,
        street2: site.street2,
        type: site.parking_type,
        violations: site.violations,
        totalViolations: site.violations.length,
        site_id: site.id,
        selected: site.id === selectedSiteId,
        name: site.name,
        lon: center[0],
        lat: center[1]
      };
    }
    return rectFeature;
  });
}

export function geojsonSource(sourceId, features) {
  let source = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: features
    }
  };

  return function(styles) {
    return {
      source: { id: sourceId, sourceObj: source },
      styles: styles
    };
  };
}

export function getParkingSpaceStyles(
  sourceId,
  options = { minZoom: 15, maxZoom: 22 }
) {
  return getRectStyles(sourceId, options);
}

function getRectStyles(sourceId, options = { minZoom: 15, maxZoom: 23 }) {
  // Space style definition

  let outlineStyle = getOutlineStyle(RECT_SPACE_OUTLINE_STYLE, sourceId);

  let styleSpace = getRectSpaceStyle(
    RECT_SPACE_STYLE,
    sourceId,
    '#7A869A',
    '#6C798F',
    options.minZoom,
    options.maxZoom
  );

  let styleTimedParkingSpace = getTimedViolationStyle(
    RECT_TIMED_VIOLATION_STYLE,
    sourceId,
    '#ff7351',
    '#6C798F',
    options.minZoom,
    options.maxZoom
  );

	let stylePaidParkingSpace = getPaidViolationStyle(
		RECT_PAID_VIOLATION_STYLE,
		sourceId,
		'#0265ff',
		'#6C798F',
		options.minZoom,
		options.maxZoom
	);

	let styleNoParkingSpace = getParkingViolationStyle(
    RECT_PARKING_VIOLATION_STYLE,
    sourceId,
    '#6554c0',
    '#6C798F',
    options.minZoom,
    options.maxZoom
  );

  let styleMultipleViolationParkingSpace = getMultipleViolationStyle(
    RECT_MULTIPLE_VIOLATION_STYLE,
    sourceId,
    '#ff7351',
    '#6C798F',
    options.minZoom,
    options.maxZoom
  );

  let styleSelectedSpace = getSelectedSpaceStyle(
    RECT_SELECTED_SPACE_STYLE,
    sourceId,
    '#ff7351',
    '#ffffff',
    options.minZoom,
    options.maxZoom
  );

  return [
    styleSpace,
    styleTimedParkingSpace,
	stylePaidParkingSpace,
    styleNoParkingSpace,
    styleMultipleViolationParkingSpace,
    outlineStyle,
    styleSelectedSpace
  ];
}

export function getOutlineStyle(
  id,
  sourceId,
  color = '#373d49',
  minzoom = 17,
  maxzoom = 23
) {
  return {
    id: id,
    type: 'line',
    source: sourceId,
    paint: {
      'line-width': 2,
      'line-color': color
    },
    minzoom: minzoom,
    maxzoom: maxzoom
  };
}

export function getRectSpaceStyle(
  id,
  sourceId,
  color = '#41526d',
  outlineColor = '#6C798F',
  minzoom = 17,
  maxzoom = 22
) {
  return {
    id: id,
    type: 'fill',
    source: sourceId,
    paint: {
      'fill-color': color
    },
    filter: ['==', ['length', ['get', 'violations']], 0],

    minzoom: minzoom,
    maxzoom: maxzoom
  };
}

function getTimedViolationStyle(
  id,
  sourceId,
  color = '#41526d',
  outlineColor = '#6C798F',
  minzoom = 17,
  maxzoom = 22
) {
  return {
    id: id,
    type: 'fill',
    source: sourceId,
    paint: {
      'fill-color': color
    },
    filter: [
      'all',
      ['==', ['length', ['get', 'violations']], 1],
      ['==', ['at', 0, ['get', 'violations']], 'timedViolation']
    ],

    minzoom: minzoom,
    maxzoom: maxzoom
  };
}

function getPaidViolationStyle(
	id,
	sourceId,
	color = '#41526d',
	outlineColor = '#6C798F',
	minzoom = 17,
	maxzoom = 22
) {
	return {
		id: id,
		type: 'fill',
		source: sourceId,
		paint: {
			'fill-color': color
		},
		filter: [
			'all',
			['==', ['length', ['get', 'violations']], 1],
			['==', ['at', 0, ['get', 'violations']], 'paidViolation']
		],

		minzoom: minzoom,
		maxzoom: maxzoom
	};
}

function getParkingViolationStyle(
  id,
  sourceId,
  color = '#41526d',
  outlineColor = '#6C798F',
  minzoom = 17,
  maxzoom = 22
) {
  return {
    id: id,
    type: 'fill',
    source: sourceId,
    paint: {
      'fill-color': color
    },
    filter: [
      'all',
      ['==', ['length', ['get', 'violations']], 1],
      ['==', ['at', 0, ['get', 'violations']], 'parkingViolation']
    ],
    minzoom: minzoom,
    maxzoom: maxzoom
  };
}

function getMultipleViolationStyle(
  id,
  sourceId,
  color = '#41526d',
  outlineColor = '#6C798F',
  minzoom = 17,
  maxzoom = 22
) {
  return {
    id: id,
    type: 'fill',
    source: sourceId,
    paint: {
      'fill-color': color
    },
    filter: ['>', ['length', ['get', 'violations']], 1],
    minzoom: minzoom,
    maxzoom: maxzoom
  };
}

function getSelectedSpaceStyle(
  id,
  sourceId,
  color = '#ffffff',
  outlineColor = '#ffffff',
  minzoom = 17,
  maxzoom = 22
) {
  return {
    id: id,
    type: 'line',
    source: sourceId,
    paint: {
      'line-color': outlineColor,
      'line-width': 2
    },
    filter: ['==', 'selected', true],
    minzoom: minzoom,
    maxzoom: maxzoom
  };
}

export function getSpaceNameTextStyle(sourceId, color, minZoom, maxZoom) {
	return {
		id: RECT_SPACE_NAME_TEXT_STYLE,
		type: 'symbol',
		source: sourceId,
		paint: {
			'text-color': color
		},
		layout: {
			'text-field': ['get', 'name'],
			'text-max-width': 10,
			'text-allow-overlap': true,
			'text-ignore-placement': true,
			'text-font': ['Roboto Regular'],
			'text-size': {
				base: 1,
				stops: [
					[minZoom, 1],
					[20, 12],
					[22, 30]
				]
			},
			'text-justify': 'center'
		},
		minzoom: minZoom,
		maxzoom: maxZoom
	};
}
