export function addLayer(layer, map) {
  let existingSource = map.getSource(layer.source.id);
  if (existingSource) {
    map.getSource(layer.source.id).setData(layer.source.sourceObj.data);
  } else {
    map.addSource(layer.source.id, layer.source.sourceObj);
  }

  layer.styles.forEach(style => {
    let styleExists = map.getLayer(style.id);
    if (!styleExists) {
      map.addLayer(style);
    }
  });
}
