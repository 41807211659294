import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './modules';
import thunk from 'redux-thunk';
import { createHashHistory } from 'history';

const enhancers = [];
export const history = createHashHistory();
const initialState = {};
const middleware = [thunk, routerMiddleware(history)];

export default function configureStore() {
  if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  return createStore(
    createRootReducer(history),
    initialState,
    composedEnhancers
  );
}
