/*global cordova*/
import React from 'react';
import { css } from 'emotion';
import AppHeader from '../app-header';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import Button from '../button';
import crossIcon from '../../assets/cross-icon.svg';
import rightIcon from '../../assets/right-icon.svg';
import {
  GENERATE_TICKET_RECIEVED,
  GENERATE_TICKET_FAILURE
} from '../../constants/ticket';
import { isInAppEnv } from '../../utils/mobile';

function GenerateTicket(props) {
  let ticketDetails = props.ticket.ticketDetails;
  const ticketStatus = props.ticket.ticketStatus;
  const handlePrintViolation = () => {
    if (isInAppEnv()) {
      cordova.plugins.printer.print();
    } else {
      console.log('Printing is not available on this device');
    }
  };

  const handleTryAgain = () => {
    props.goToConfirmDetailsPage();
  };

  const handleCloseBtton = () => {
    props.goToMap();
  };

  const handleSettingsButton = () => {
    props.goToSettingsPage();
  };

  const renderSuccessPage = () => {
    return (
      <div className={divStyle} id="start">
        <AppHeader
          title="Ticket Details"
          crossIcon
          settingsIcon
          handleCloseBtton={handleCloseBtton}
          handleSettingsButton={handleSettingsButton}
        />
        <div className={container}>
          <div className={successmsgContainer}>
            <div>
              <img src={rightIcon} className={imgStyle} alt={'success'}></img>
            </div>
            <div className={heading}>Success!</div>
            <div className={subHeading}>Ticket successfully logged in</div>
          </div>
          <div className={discriptionContainer}>
            <div>
              <div className={title}>Ticket Details</div>
              <div className={listStyle}>
                <div className={listItemStyle}>Site Id</div>
                <div className={listItemValueStyle}>{ticketDetails.id}</div>
              </div>
              <div className={listStyle}>
                <div className={listItemStyle}>Ticket Id</div>
                <div className={listItemValueStyle}>
                  {ticketDetails.ticketId}
                </div>
              </div>
              <div className={listStyle}>
                <div className={listItemStyle}>Date time</div>
                <div className={listItemValueStyle}>
                  {ticketDetails.endTime}
                </div>
              </div>
            </div>
            <Button
              btnClassName={nextBtnContainer}
              text="PRINT VIOLATION"
              buttonClickCallback={handlePrintViolation}
            />
            <Button
              btnClassName={doneButtonStyle}
              btnTextClassName={doneButtonTextStyle}
              text="DONE"
              buttonClickCallback={handleCloseBtton}
            />
          </div>
        </div>
      </div>
    );
  };
  const renderFailurePage = () => {
    return (
      <div className={divStyle}>
        <AppHeader
          title="Create ticket"
          crossIcon
          settingsIcon
          handleCloseBtton={handleCloseBtton}
        />
        <div className={container}>
          <div className={failMsgContainer}>
            <div>
              <img src={crossIcon} className={imgStyle} alt={'failure'}></img>
            </div>
            <div className={heading}>Error!</div>
            <div className={subHeading}>Ticket logging failed</div>
          </div>
          <Button
            btnClassName={nextBtnContainer}
            text="TRY AGAIN"
            buttonClickCallback={handleTryAgain}
          />
        </div>
      </div>
    );
  };
  return (
    <div>
      {ticketStatus === GENERATE_TICKET_RECIEVED ? renderSuccessPage() : null}
      {ticketStatus === GENERATE_TICKET_FAILURE ? renderFailurePage() : null}
    </div>
  );
}

const divStyle = css({
  height: '100vh',
  backgroundColor: '#fafbfc'
});
const container = css({
  margin: '24px 8px 0 8px'
});
const imgStyle = css({
  width: '48px',
  height: '48px',
  backgroundColor: '#ffffff',
  borderRadius: '50% 50% 50% 50%'
});
const successmsgContainer = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: '#57d9a3',
  color: '#ffffff',
  height: '166px',
  fontFamily: 'sans-serif',
  fontStyle: 'normal',
  border: '1px solid #ebecf0',
  borderRadius: '1px',
  boxShadow: '2px 2px 2px 2px #23000000'
});
const failMsgContainer = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: '#ff7351',
  color: '#ffffff',
  height: '166px',
  fontFamily: 'sans-serif',
  fontStyle: 'normal',
  border: '1px solid #ebecf0',
  borderRadius: '1px',
  boxShadow: '2px 2px 2px 2px #23000000'
});
const heading = css({
  marginTop: '14px',
  fontSize: '18px'
});
const subHeading = css({
  marginTop: '6px',
  fontSize: '14px'
});
const discriptionContainer = css({
  height: '154px',
  marginTop: '16px',
  backgroundColor: '#ffffff',
  fontFamily: 'sans-serif',
  fontStyle: 'normal',
  border: '1px solid #ebecf0',
  borderRadius: '1px',
  boxShadow: '2px 2px 2px 2px #23000000'
});
const title = css({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '16px',
  fontSize: '18px',
  color: '#212121',
  height: '50px'
});

const listItemValueStyle = css({
  color: '#000000',
  marginRight: '16px',
  fontWeight: 600
});
const listStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: 'sans-serif',
  fontSize: '14px',
  fontStyle: 'normal',
  margin: '8px 0 0 16px',
  minHeight: '21px'
});
const listItemStyle = css({
  color: '#757575',
  minWidth: '50%'
});

const nextBtnContainer = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  bottom: '16px',
  right: '16px',
  left: '16px',
  height: '56px',
  width: 'calc(100% - 32px)',
  borderRadius: '2px',
  boxShadow: '0px 1px 3px #dbdbdb'
});

const doneButtonStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  bottom: '75px',
  right: '16px',
  left: '16px',
  height: '56px',
  width: 'calc(100% - 32px)',
  borderRadius: '2px',
  boxShadow: '0px 1px 3px #dbdbdb',
  margin: '8px 0',
  backgroundColor: '#ffffff',
  border: '1px solid #bdbdbd',
  '&:hover:enabled': {
    backgroundColor: '#bdbdbd'
  },
  '&:active:enabled': {
    backgroundColor: '#bdbdbd'
  }
});

const doneButtonTextStyle = css({
  color: '#0151cb'
});

const mapStateToProps = state => ({
  ticket: state.ticket,
  map: state.map
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToMap: () => push('/map'),
      goToConfirmDetailsPage: () => push('/ticketDetails'),
      goToSettingsPage: () => push('/settings')
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateTicket);
