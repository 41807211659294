import React from 'react';
import { css, keyframes } from 'emotion';

export default function MapLoader(props) {
  if (props.showLoader) {
    return (
      <div className={dotsContainer + ' ' + props.loaderContainer}>
        <div className={dotFlashing} />
      </div>
    );
  }
  return null;
}

const dotsContainer = css({
  position: 'absolute',
  top: 'calc(75px + env(safe-area-inset-top))',
  backgroundColor: '#ffffff',
  padding: '10px 30px',
  left: 'calc(50% - 30px)',
  borderRadius: '30px',
  zIndex: 3,
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)'
});

const dotFlashingKeyFrames = keyframes`
  0% {
    background-color: #0151CB;
  }

  50%, 100% {
    background-color: #9ac2ff;
  }
`;

const dotFlashing = css({
  width: '5px',
  height: '5px',
  borderRadius: '2.5px',
  backgroundColor: '#0151CB',
  color: '#0151CB',
  WebkitAnimation: `${dotFlashingKeyFrames} 1s infinite linear alternate`,
  animation: `${dotFlashingKeyFrames} 1s infinite linear alternate`,
  WebkitAnimationDelay: '.5s',
  animationDelay: '.5s',
  position: 'relative',
  ':before': {
    content: `''`,
    display: 'inline-block',
    position: 'absolute',
    top: '0px',
    left: '-10px',
    width: '5px',
    height: '5px',
    borderRadius: '2.5px',
    backgroundColor: '#0151CB',
    color: '#0151CB',
    boxSizing: 'border-box',
    WebkitAnimation: `${dotFlashingKeyFrames} 1s infinite alternate`,
    animation: `${dotFlashingKeyFrames} 1s infinite alternate`,
    animationDelay: '0s',
    WebkitAnimationDelay: '0s'
  },
  ':after': {
    content: `''`,
    display: 'inline-block',
    position: 'absolute',
    top: '0px',
    left: '10px',
    width: '5px',
    height: '5px',
    borderRadius: '2.5px',
    backgroundColor: '#0151CB',
    color: '#0151CB',
    WebkitAnimation: `${dotFlashingKeyFrames} 1s infinite alternate`,
    animation: `${dotFlashingKeyFrames} 1s infinite alternate`,
    animationDelay: '1s',
    WebkitAnimationDelay: '1s'
  }
});
