import React, { useEffect } from 'react';
import { css } from 'emotion';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';

import {
  selectCity,
  getSitesViolations1xLevel,
  getCities
} from '../../modules/deployments';
import expandIcon from '../../assets/expand-icon.svg';
import { FETCH_CITIES_RECEIVED } from '../../constants/sites';

function Deployments(props) {
  const displayMap = index => {
    props.selectCity(index);
    props.goToMap();
  };

  useEffect(() => {
    if (props.citiesStatus === FETCH_CITIES_RECEIVED) {
      props.getSitesViolations1xLevel();
      let intervalId = setInterval(props.getSitesViolations1xLevel, 10000);

      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.citiesStatus]);

  useEffect(() => {
    if (props.user.isLoggedIn && props.citiesStatus !== FETCH_CITIES_RECEIVED) {
      props.getCities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.isLoggedIn, props.citiesStatus]);

  function violationsText(count) {
    if (count === null) {
      return '-';
    } else {
      return count > 0 ? count + ' Violations' : 'No Violations';
    }
  }

  return (
    <div className={divStyle}>
      <div>
        {props.cities &&
          props.cities.map((city, index) => {
            return (
              <div key={index}>
                <div className={container} onClick={() => displayMap(index)}>
                  <div className={subContainer}>
                    <div className={locationStyle}>{city.name}</div>
                    <div
                      className={classnames({
                        [`${violationRedStyle}`]:
                          city.total_violations && city.total_violations > 0,
                        [`${violationStyle}`]: true
                      })}
                    >
                      {violationsText(city.total_violations)}
                    </div>
                  </div>
                  <div>
                    <img
                      className={imgStyle}
                      src={expandIcon}
                      alt="expantion icon"
                    />
                  </div>
                </div>
                <hr className={hrStyle} />
              </div>
            );
          })}
      </div>
    </div>
  );
}

const divStyle = css({
  margin: 'auto',
  borderRadius: '2px',
  border: 'solid 2px #f4f5f7'
});
const locationStyle = css({
  marginLeft: '16px',
  color: '#212121',
  fontSize: '16px',
  fontFamily: 'sans-serif',
  fontStyle: 'normal'
});
const container = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '80px'
});
const hrStyle = css({
  margin: 0,
  border: 'none',
  borderBottom: 'solid 1px #dbdbdb'
});
const subContainer = css({
  display: 'flex',
  flexDirection: 'column'
});
const imgStyle = css({
  marginRight: '16px'
});
const violationStyle = css({
  marginLeft: '16px',
  color: '#757575',
  fontSize: '14px',
  fontFamily: 'sans-serif',
  fontStyle: 'normal'
});
const violationRedStyle = css({
  color: '#ff7351'
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToMap: () => push('/map'),
      selectCity,
      getSitesViolations1xLevel,
      getCities
    },
    dispatch
  );
const mapStateToProps = state => ({
  cities: state.deployments.cities,
  user: state.user,
  citiesStatus: state.deployments.citiesStatus
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Deployments);
