import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect';

import { isTokenValid } from '../../utils/user';
import { history } from '../../store';

import PageNotFound from '../page-not-found';
import LoginContainer from '../login';
import ResetPassword from '../reset-password';
import Homepage from '../homepage';
import Map from '../map';
import VehicleVerification from '../create-ticket/vehicleVerification';
import VehicleDetails from '../create-ticket/vehicleDetails';
import AddViolations from '../create-ticket/addViolations';
import ViolationImage from '../create-ticket/violationImage';
import TicketDetails from '../create-ticket/ticketDetails';
import GenerateTicket from '../create-ticket/generateTicket';
import Settings from '../deployments/settings';
import ActionHistory from '../action-history/actionHistory';
import { getStore } from '../../index';
import { USER_LOGGED_OUT } from '../../constants/user';

const userIsAuthenticated = connectedReduxRedirect({
  redirectPath: '/',
  authenticatedSelector: state => {
    let loginStatus = state.user.isLoggedIn;
    let isLoggedIn = typeof loginStatus !== 'undefined' && loginStatus;
    if (isLoggedIn) {
      let isValid = isTokenValid(state.user);
      if (!isValid) {
        getStore().dispatch({ type: USER_LOGGED_OUT });
      }
      return isValid;
    }
    return false;
  },
  wrapperDisplayName: 'UserIsAuthenticated',
  // This should be a redux action creator
  redirectAction: path => () => {
    history.push('/login');
  }
});

function App(props) {
  return (
    <div>
      <main>
        <Switch>
			<Route exact path="/" component={userIsAuthenticated(Homepage)} />
			<Route exact path="/login" component={LoginContainer} />
			<Route exact path="/resetpassword" component={ResetPassword} />
			<Route exact path="/map" component={userIsAuthenticated(Map)} />
			<Route exact path="/addViolations" component={userIsAuthenticated(AddViolations)} />
			<Route exact path="/vehicleVerification" component={userIsAuthenticated(VehicleVerification)} />
			<Route exact path="/vehicleDetails" component={userIsAuthenticated(VehicleDetails)} />
			<Route exact path="/violationImage" component={userIsAuthenticated(ViolationImage)} />
			<Route exact path="/ticketDetails" component={userIsAuthenticated(TicketDetails)} />
			<Route exact path="/generateTicket" component={userIsAuthenticated(GenerateTicket)} />
			<Route exact path="/settings" component={userIsAuthenticated(Settings)} />
			<Route exact path="/actionHistory" component={userIsAuthenticated(ActionHistory)} />
			<Route path="*" exact={true} component={PageNotFound} />
        </Switch>
      </main>
    </div>
  );
}

export default App;
