import React, { useState, useEffect } from 'react';
import { css, keyframes } from 'emotion';
import moment from 'moment-timezone';
import classnames from 'classnames';

import AppHeader from '../app-header';
import { connect } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { generateTicket } from '../../modules/ticket';
import {
  GENERATE_TICKET_RECIEVED,
  GENERATE_TICKET_FAILURE
} from '../../constants/ticket';
import {
  NO_PARKING,
  METER_EXPIRED,
  TIME_EXPIRED
} from '../../constants/ticket';
function TicketDetails(props) {
  let ticketDetails = props.ticket.ticketDetails;
  let siteId = ticketDetails.id;
  const [createTicketClicked, setCreateTicketClicked] = useState(false);
  if (props.map.violationDetails[siteId] !== undefined) {
    var sessionId = props.map.violationDetails[siteId].session_id;
    var startTime = props.map.violationDetails[siteId].session_start;
  }
  const [violations, setViolations] = useState([]);
  const displayDuration = () => {
    if (ticketDetails.since.timed_violation !== undefined) {
      var tViolationDuration = moment
        .utc(
          moment()
            .tz(ticketDetails.geojson.properties['time-zone'])
            .diff(
              moment.tz(
                ticketDetails.since.timed_violation
                  .replace('T', ' ')
                  .replace('Z', ''),
                ticketDetails.geojson.properties['time-zone']
              )
            )
        )
        .format('HH:mm:ss');
    }
    if (ticketDetails.since.parking_violation !== undefined) {
      var pViolationDuration = moment
        .utc(
          moment()
            .tz(ticketDetails.geojson.properties['time-zone'])
            .diff(
              moment.tz(
                ticketDetails.since.parking_violation
                  .replace('T', ' ')
                  .replace('Z', ''),
                ticketDetails.geojson.properties['time-zone']
              )
            )
        )
        .format('HH:mm:ss');
    }
    if (ticketDetails.since.paid_violation !== undefined) {
      var mViolationDuration = moment
        .utc(
          moment()
            .tz(ticketDetails.geojson.properties['time-zone'])
            .diff(
              moment.tz(
                ticketDetails.since.paid_violation
                  .replace('T', ' ')
                  .replace('Z', ''),
                ticketDetails.geojson.properties['time-zone']
              )
            )
        )
        .format('HH:mm:ss');
    }
    var duration = [tViolationDuration, pViolationDuration, mViolationDuration];
    var maxDuration = duration.sort(function(a, b) {
      return b - a;
    });
    return maxDuration[0];
  };
  const displayViolation = () => {
    return violations.map((violation, index) => {
      return (
        <div key={index} className={violatioItemStyle}>
          {violation}
        </div>
      );
    });
  };
  useEffect(() => {
    if (props.ticket.ticketDetails.violations !== undefined) {
      let tempViolationsArray = [];
      ticketDetails.violations.forEach(violationObj => {
        if (violationObj['type'] === 0) {
          tempViolationsArray.push(NO_PARKING);
        }
        if (violationObj['type'] === 1) {
          tempViolationsArray.push(METER_EXPIRED);
        }
        if (violationObj['type'] === 2) {
          tempViolationsArray.push(TIME_EXPIRED);
        }
        setViolations(tempViolationsArray);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ticket.ticketStatus]);
  useEffect(() => {
    if (
      props.map.violationDetails[siteId] !== undefined &&
      (props.ticket.ticketStatus === GENERATE_TICKET_RECIEVED ||
        props.ticket.ticketStatus === GENERATE_TICKET_FAILURE)
    ) {
      props.goToGernateTicket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ticket.ticketStatus]);

  const handleNextButton = () => {
    setCreateTicketClicked(true);
    props.generateTicket(sessionId, startTime);
  };
  const handleSettingsButton = () => {
    props.goToSettingsPage();
  };
  return (
    <div className={divStyle}>
      <AppHeader
        title="Create Ticket"
        settingsIcon
        backIcon
        handleBackButton={props.prevPage}
        handleSettingsButton={handleSettingsButton}
      />
      <div>
        <div className={headerStyle}>
          <div className={progressBarStyle}></div>{' '}
        </div>
        <div className={container}>
          <div className={violationDetailsContainer}>
            <div className={heading}> Violation Details </div>
            <div className={listStyle}>
              <div className={listItemStyle}>Site Id</div>
              <div className={listItemValueStyle}>{ticketDetails.id}</div>
            </div>
            <div className={listStyle}>
              <div className={listItemStyle}>Violation Type(s)</div>
              <div className={listItemValueStyle}>
                {ticketDetails.violations &&
                Object.keys(ticketDetails.violations).length > 0
                  ? displayViolation()
                  : null}
              </div>
            </div>
            <div className={listStyle}>
              <div className={listItemStyle}>Duration of Violation</div>
              <div className={listItemValueStyle}>
                {ticketDetails.since &&
                Object.keys(ticketDetails.since).length > 0
                  ? displayDuration()
                  : null}
              </div>
            </div>
          </div>
          <div className={vehicleDetailsContainer}>
            <div className={heading}> Vehicle Details </div>
            <div className={listStyle}>
              <div className={listItemStyle}>License Plate Number</div>
              <div className={listItemValueStyle}>
                {ticketDetails.licensePlateNumber}
              </div>
            </div>
            <div className={listStyle}>
              <div className={listItemStyle}>VIN</div>
              <div className={listItemValueStyle}>
                {ticketDetails.verificationIdentificationNumber}
              </div>
            </div>
            <div className={listStyle}>
              <div className={listItemStyle}>Vehicle Manufacturer</div>
              <div className={listItemValueStyle}>
                {ticketDetails.vehicleManufacturer}
              </div>
            </div>
            <div className={listStyle}>
              <div className={listItemStyle}>Vehicle Type</div>
              <div className={listItemValueStyle}>
                {ticketDetails.vehicleType}
              </div>
            </div>
            <div className={listStyle}>
              <div className={listItemStyle}>Vehicle Color</div>
              <div className={listItemValueStyle}>
                {ticketDetails.vehicleColor}
              </div>
            </div>
          </div>
        </div>
        <button
          className={classnames({
            [`${nextBtnContainer}`]: true,
            [`${enabledNextBtnConatainer}`]: !createTicketClicked
          })}
          onClick={handleNextButton}
        >
          {createTicketClicked ? (
            <div>
              Generating<span className={loaderDotStyle}>.</span>
              <span className={loaderDotStyle}>.</span>
              <span className={loaderDotStyle}>.</span>
            </div>
          ) : (
            <span>CREATE TICKET</span>
          )}
        </button>
      </div>
    </div>
  );
}
const divStyle = css({
  height: '100vh',
  backgroundColor: '#fafbfc'
});
const headerStyle = css({
  height: '8px',
  backgroundColor: '#ffffff'
});
const progressBarStyle = css({
  backgroundColor: '#34b37e',
  width: '95%',
  height: '8px',
  borderRadius: '0px 5px 5px 0px'
});
const container = css({
  margin: '16px 8px 0 8px'
});
const violationDetailsContainer = css({
  backgroundColor: '#ffffff',
  minHeight: '154px',
  fontFamily: 'sans-serif',
  fontStyle: 'normal',
  border: '1px solid #ebecf0',
  borderRadius: '1px',
  boxShadow: '2px 2px 2px 2px #23000000'
});
const dotsKeyframes = keyframes`
  50% { color: transparent }
`;

const loaderDotStyle = css({
  animation: `1s ${dotsKeyframes} infinite`,
  ':nth-child(2)': {
    animationDelay: '250ms'
  },
  ':nth-child(3)': {
    animationDelay: '500ms'
  }
});
const violatioItemStyle = css({
  display: 'flex',
  justifyContent: 'flex-end'
});
const vehicleDetailsContainer = css({
  height: '228px',
  marginTop: '16px',
  backgroundColor: '#ffffff',
  fontFamily: 'sans-serif',
  fontStyle: 'normal',
  marginBottom: '86px',
  border: '1px solid #ebecf0',
  borderRadius: '1px',
  boxShadow: '2px 2px 2px 2px #23000000'
});
const heading = css({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '16px',
  fontSize: '18px',
  color: '#212121',
  height: '50px'
});
const listItemValueStyle = css({
  color: '#000000',
  marginRight: '16px',
  fontWeight: 600
});
const listStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: 'sans-serif',
  fontSize: '14px',
  fontStyle: 'normal',
  margin: '8px 0 0 16px',
  minHeight: '21px'
});
const listItemStyle = css({
  color: '#757575',
  minWidth: '50%'
});

const nextBtnContainer = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#c1c7d0',
  color: '#ebecf0',
  position: 'fixed',
  bottom: '0px',
  left: '0px',
  fontSize: '16px',
  margin: '16px',
  height: '56px',
  width: 'calc(100% - 32px)',
  borderRadius: '2px',
  boxShadow: '0px 1px 3px #dbdbdb',
  '&:focus': {
    outline: 'none'
  }
});

const enabledNextBtnConatainer = css({
  backgroundColor: '#0152cc',
  color: '#ffffff',
  '&:focus': {
    outline: 'none'
  }
});
const mapStateToProps = state => ({
  ticket: state.ticket,
  map: state.map
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      prevPage: () => goBack(),
      goToGernateTicket: () => push('/generateTicket'),
      goToSettingsPage: () => push('/settings'),
      generateTicket
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketDetails);
