import React from 'react';
import Icon from 'react-fa';
import { css } from 'emotion';

export default function Button(props) {
  const buttonClick = () => {
    props.buttonClickCallback();
  };

  return (
    <div style={props.buttonContainerStyle}>
      <button
        className={defaultButtonClass + ' ' + props.btnClassName}
        onClick={buttonClick}
        disabled={props.disabled}
      >
        {props.text ? (
          <span className={spanClass + ' ' + props.btnTextClassName}>
            {props.text}
          </span>
        ) : props.icon ? (
          <Icon name={props.icon} className="icon" style={props.iconStyle} />
        ) : null}
      </button>
    </div>
  );
}

const defaultButtonClass = css({
  background: '#0152cc',
  border: 'none',
  color: '#000000',
  position: 'relative',
  margin: 0,
  minWidth: '64px',
  padding: '14px 35px',
  display: 'inline-block',
  overflow: 'hidden',
  willChange: 'box-shadow',
  outline: 'none',
  cursor: 'pointer',
  textDecoration: 'none',
  textAlign: 'center',
  verticalAlign: 'middle',
  borderRadius: '2px',
  textTransform: 'uppercase',
  '&::-moz-focus-inner': {
    border: 0
  },
  '&:hover:enabled': {
    backgroundColor: '#0647a6'
  },
  '&:active:enabled': {
    backgroundColor: '#0647a6'
  }
});

const spanClass = css({
  color: '#FFFFFF',
  fontFamily: 'sans-serif',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontSize: '16px',
  letterSpacing: '0.03',
  lineHeight: '21px'
});
