import React from 'react';
import AppHeader from '../app-header';
import Deployments from '../deployments';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
function Homepage(props) {
  const handleSettingsButton = () => {
    props.goToSettingsPage();
  };
  return (
    <div>
      <AppHeader
        title="Sites"
        settingsIcon
        handleSettingsButton={handleSettingsButton}
      />
      <Deployments />
    </div>
  );
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToSettingsPage: () => push('/settings')
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(Homepage);
