import React, { useState, useEffect, useLayoutEffect } from 'react';
import { css } from 'emotion';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { createTicket } from '../../modules/ticket';
import { ignoreViolation } from '../../modules/map';
import Button from '../button';
import { isInAppEnv, navigateTo } from '../../utils/mobile';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

const ParkingSpaceDetails = React.forwardRef((props, ref) => {
	const [spaceDetails, setSpaceDetails] = useState(null);

	const handleIssueTicket = () => {
		props.createTicket(spaceDetails);
		props.goToViolationDetails();
	};

	useLayoutEffect(() => {
		if (ref && ref.current && ref.current.clientHeight) {
			props.setSpaceDetailsHeight(ref.current.clientHeight);
		}
	});

	useEffect(() => {
		let getSelectedSpace = JSON.parse(
			JSON.stringify(
				props.map.sites.filter(site => site.id === props.siteId)[0]
			)
		);
		if (getSelectedSpace.violations.length > 1) {
			let violations;
			if (
				moment
					.tz(
						getSelectedSpace.since.parking_violation,
						getSelectedSpace.geojson.properties['time-zone']
					)
					.isBefore(
						moment.tz(
							getSelectedSpace.since.timed_violation,
							getSelectedSpace.geojson.properties['time-zone']
						)
					)
			) {
				violations = ['parkingViolation', 'timedViolation', 'paidViolation'];
			} else {
				violations = ['paidViolation', 'timedViolation', 'parkingViolation'];
			}
			getSelectedSpace.violations = violations;
		}
		setSpaceDetails(getSelectedSpace);
	}, [props.map.sites, props.siteId]);

	const onNavigate = () => {
		let coords = spaceDetails.geojson.geometry.coordinates[0][0];
		if (isInAppEnv()) {
			navigateTo([coords[1], coords[0]]);
		} else {
			console.log('Will navigate the user to ' + coords + ' in device');
		}
	};

	return spaceDetails ? (
		<div ref={ref} className={overlayContainer}>
			<div className={navigateButtonStyle} onClick={onNavigate}>
				Navigate
			</div>
			<div className={spaceNameStyle}>{spaceDetails.name}</div>
			<div className={spaceAddressStyle}>
				{spaceDetails.street1 + ', ' + spaceDetails.street2}
			</div>
			<div className={violationsTypeStyle}>
				{spaceDetails.violations.map(violation => {
					let classStyle = '';
					if (violation === 'parkingViolation') {
						classStyle = noParkingBubbleStyle;
					} else if (violation === 'timedViolation') {
						classStyle = timedParkingBubbleStyle;
					} else {
						classStyle = meterParkingBubbleStyle;
					}
					let bubbleText = '';
					if (violation === 'parkingViolation') {
						bubbleText = 'No Parking';
					} else if (violation === 'timedViolation') {
						bubbleText = 'Time Expired';
					} else {
						bubbleText = 'Meter Expired';
					}
					return (
						<div
							key={props.siteId + violation}
							className={bubbleClass + ' ' + classStyle}
						>
							{bubbleText}
						</div>
					);
				})}
			</div>
			{spaceDetails.since && Object.keys(spaceDetails.since).length > 0 ? (
				<div className={violationDetailStyle}>
					{spaceDetails.violations.map(violation => {
						if (
							spaceDetails.since.parking_violation &&
							violation === 'parkingViolation'
						) {
							return (
								<div key={violation}>
									{'Parked outside permitted time. Parked since ' +
										moment
											.tz(
												spaceDetails.since.parking_violation,
												spaceDetails.geojson.properties['time-zone']
											)
											.format('HH:mm:ss, MMMM Do YYYY.')}
								</div>
							);
						} else if (
							spaceDetails.since.timed_violation &&
							violation === 'timedViolation'
						) {
							return (
								<div key={violation}>
									{'Parking time limit exceeded. Parked since ' +
										moment
											.tz(
												spaceDetails.since.timed_violation,
												spaceDetails.geojson.properties['time-zone']
											)
											.format('HH:mm:ss, MMMM Do YYYY.')}
								</div>
							);
						} else if (
							spaceDetails.since.paid_violation &&
							violation === 'paidViolation'
						) {
							return (
								<div key={violation}>
									{'Parking meter expired. Parked since ' +
										moment
											.tz(
												spaceDetails.since.paid_violation,
												spaceDetails.geojson.properties['time-zone']
											)
											.format('HH:mm:ss, MMMM Do YYYY.')}
								</div>
							);
						} else {
							return null;
						}
					})}
				</div>
			) : null}
			{spaceDetails.violations.length > 0 ? (
				<Button
					btnClassName={ignoreButtonStyle}
					btnTextClassName={ignoreButtonTextStyle}
					buttonClickCallback={() =>
						props.ignoreViolation(props.siteId, spaceDetails)
					}
					text="IGNORE VIOLATION"
				/>
			) : null}
			<Button
				btnClassName={issueTicketButtonStyle}
				text="ISSUE TICKET"
				buttonClickCallback={handleIssueTicket}
			/>
		</div>
	) : null;
});

const overlayContainer = css({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	position: 'absolute',
	bottom: '0',
	padding: '16px',
	backgroundColor: '#ffffff',
	paddingBottom: 'calc(env(safe-area-inset-bottom) + 8px)',
	boxShadow: '0 1px 3px #1e0000',
	boxSizing: 'border-box'
});

const navigateButtonStyle = css({
	backgroundColor: '#112138',
	borderRadius: '40px',
	width: '100px',
	height: '48px',
	textTransform: 'uppercase',
	fontFamily: 'Roboto',
	textAlign: 'center',
	position: 'absolute',
	top: '-22px',
	left: 'calc(100vw - 120px)',
	fontSize: '14px',
	color: '#ffffff',
	fontWeight: 500,
	lineHeight: '46px',
	boxShadow: '0 6px 12px 0 rgba(0, 0, 0, 0.6)'
});

const spaceNameStyle = css({
	fontSize: '18px',
	color: '#212121',
	lineHeight: '27px'
});

const spaceAddressStyle = css({
	fontSize: '14px',
	color: '#757575',
	margin: '8px 0',
	lineHeight: '21px'
});

const violationsTypeStyle = css({
	boxSizing: 'border-box',
	display: 'flex',
	fontWeight: 500,
	fontSize: '12px',
	textTransform: 'uppercase',
	color: '#ffffff'
});

const bubbleClass = css({
	borderRadius: '48px',
	padding: '0px 20px',
	lineHeight: '32px',
	margin: '8px 8px 8px 0'
});

const noParkingBubbleStyle = css({
	backgroundColor: '#6554c0'
});

const timedParkingBubbleStyle = css({
  backgroundColor: '#ff7351'
});

const meterParkingBubbleStyle = css({
  backgroundColor: '#0265ff'
});

const violationDetailStyle = css({
	backgroundColor: '#fafbfc',
	padding: '16px',
	margin: '8px -16px',
	color: '#757575',
	fontSize: '16px',
	lineHeight: '24px'
});

const issueTicketButtonStyle = css({
	width: '100%',
	height: '56px',
	borderRadius: '2px',
	margin: '8px 0',
	boxShadow: '0px 1px 3px #dbdbdb'
});

const ignoreButtonStyle = css({
	width: '100%',
	height: '56px',
	borderRadius: '2px',
	boxShadow: '0px 1px 3px #dbdbdb',
	margin: '8px 0',
	backgroundColor: '#ffffff',
	border: '1px solid #bdbdbd',
	'&:hover:enabled': {
		backgroundColor: '#bdbdbd'
	},
	'&:active:enabled': {
		backgroundColor: '#bdbdbd'
	}
});

const ignoreButtonTextStyle = css({
	color: '#0151cb'
});

const mapStateToProps = state => ({
	map: state.map
});
const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			goToViolationDetails: () => push('/addViolations'),
			createTicket,
			ignoreViolation
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ParkingSpaceDetails);
