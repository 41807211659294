import React from 'react';
import { css } from 'emotion';
import mapStyleIcon from '../../assets/map_layers.png'

export default function MapControls(props) {
  return (
	  <div className={mapControlsClass}>
		  <div className={mapLayersButtonClass} onClick={props.onMapLayersButtonClick}>
			  <img src={mapStyleIcon} alt="" />
		  </div>
	  </div>
  );
}

const mapControlsClass = css({
  bottom: 'calc(env(safe-area-inset-bottom) + 10px)',
  right: '10px',
  position: 'absolute',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  height: '45px',
  justifyContent: 'space-between',
  textTransform: 'capitalize',
  color: '#ffffff'
});

const mapLayersButtonClass = css({
	backgroundColor: '#ffffff',
	borderRadius: '5px',
	padding: '4px',
	right: '0px',
	width: '20px',
	height: '20px',
	boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.08), 0 8px 8px 0 rgba(0,0,0,0.24)',
	img: {
		width: '20px'
	},
	cursor: 'pointer'
});
