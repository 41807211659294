import React, { useState, useEffect } from 'react';
import { css } from 'emotion';
import { goBack } from 'connected-react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AppHeader from '../app-header';
import moment from 'moment-timezone';
import HistoryTickets from './historyTickets';
import EmptyHistory from './emptyHistory';
import {
  TICKET_HISTORY_RECIEVED,
  TICKET_HISTORY_REQUESTED
} from '../../constants/ticket';
import Loader from '../map-loader/index';
import { resetTicketHistory } from '../../modules/ticket';
function ActionHistory(props) {
  const [tab, setTab] = useState(0);
  const [filteredTickets, setFilteredTickets] = useState(undefined);
  let history = props.ticket.history;

  const handleToday = () => {
    getFilteredData('day');
    setTab(0);
  };
  const handleLastWeek = () => {
    getFilteredData('week', 7);
    setTab(1);
  };
  const handleLastMonth = () => {
    getFilteredData('month', 30);
    setTab(2);
  };
  const goToPrevPage = () => {
    props.resetTicketHistory();
    props.prevPage();
  };
  useEffect(() => {
    if (props.ticket.ticketHistoryStatus === TICKET_HISTORY_RECIEVED) {
      getFilteredData('day');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ticket.ticketHistoryStatus]);
  const getFilteredData = (duration, daysToReduce) => {
    let TODAY = moment();
    let tempTickets = [];
    history &&
      history.tickets.forEach(ticketObj => {
        if (ticketObj.time_zone !== undefined) {
          TODAY = moment.tz(TODAY, ticketObj.time_zone).format('YYYY-MM-DD');
        }
        switch (duration) {
          case 'day':
            if (moment(ticketObj.created_at).isSame(moment(TODAY), duration)) {
              tempTickets.push(ticketObj);
            }
            break;
          case 'week':
          case 'month':
            if (
              moment(ticketObj.created_at).isBetween(
                moment(TODAY).subtract(daysToReduce, 'day'),
                moment(TODAY)
              )
            ) {
              tempTickets.push(ticketObj);
            }
            break;
          default:
            break;
        }
      });
    setFilteredTickets(tempTickets);
  };

  function showTickets(tickets) {
    if (tickets.length === 0) {
      return <EmptyHistory />;
    }
    return <HistoryTickets history={tickets} />;
  }

  function showLoader(showLoader) {
    if (showLoader) {
      return (
        <Loader showLoader={true} loaderContainer={loaderContainer}></Loader>
      );
    }
    return null;
  }

  const ticketHistoryStatus = props.ticket.ticketHistoryStatus;
  const shouldShowLoader = ticketHistoryStatus === TICKET_HISTORY_REQUESTED;
  const receivedTickets =
    ticketHistoryStatus === TICKET_HISTORY_RECIEVED &&
    filteredTickets !== undefined;

  return (
    <div className={divStyle} id="start">
      <AppHeader
        backIcon
        title="Action History"
        handleBackButton={goToPrevPage}
      />
      <div className={tabStyle}>
        <div
          className={
            tab === 0 ? headingStyle + ' ' + borderStyle : headingStyle
          }
          onClick={() => handleToday()}
        >
          TODAY
        </div>
        <div
          className={
            tab === 1 ? headingStyle + ' ' + borderStyle : headingStyle
          }
          onClick={() => handleLastWeek()}
        >
          LAST WEEK
        </div>
        <div
          className={
            tab === 2 ? headingStyle + ' ' + borderStyle : headingStyle
          }
          onClick={() => handleLastMonth()}
        >
          LAST MONTH
        </div>
      </div>
      <div className={historyContainer}>
        {showLoader(shouldShowLoader)}
        {receivedTickets ? showTickets(filteredTickets) : null}
      </div>
    </div>
  );
}

const divStyle = css({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  backgroundColor: '#fafbfc'
});
const tabStyle = css({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#112138',
  color: '#b3b5b4',
  minHeight: '48px',
  width: '100%',
  fontSize: '14px',
  fontFamily: 'sans-serif',
  fontStyle: 'normal'
});
const headingStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '33%',
  minHeight: '48px'
});
const borderStyle = css({
  color: '#ffffff',
  margin: 0,
  border: 'none',
  borderBottom: 'solid 4px #ffe89b'
});
const historyContainer = css({
  overflow: 'scroll',
  display: 'flex',
  flexDirection: 'column',
  WebkitOverflowScrolling: 'touch'
});

const loaderContainer = css({
  position: 'absolute',
  top: 'calc(120px + env(safe-area-inset-top))'
});
const mapStateToProps = state => ({
  ticket: state.ticket
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      prevPage: () => goBack(),
      resetTicketHistory
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionHistory);
