import React from 'react';
import HistoryTicket from './historyTicket';
import { css } from 'emotion';
import classnames from 'classnames';
function HistoryTickets(props) {
  return (
    <div>
      {props.history.map((ticketObj, index) => {
        let violation = [];
        ticketObj.violations.forEach(vio => {
          violation.push(vio['type']);
        });
        let colorStyle;
        if (violation !== undefined && violation.length !== 0) {
          colorStyle = classnames(
            sideBarStyle,
            { [`${multiVioStyle}`]: violation.length > 1 },
            {
              [`${noParkingStyle}`]:
                violation[0] === 0 && violation.length === 1
            },
            {
              [`${timeExpiredStyle}`]:
                violation[0] === 2 && violation.length === 1
            },
            {
              [`${meterExpiredStyle}`]:
                violation[0] === 1 && violation.length === 1
            }
          );
        }
        return (
          <HistoryTicket {...ticketObj} key={index} colorStyle={colorStyle} />
        );
      })}
    </div>
  );
}

const multiVioStyle = css({
  width: '8px',
  height: 'auto',
  backgroundColor: '#ff7351'
});

const noParkingStyle = css({
  width: '8px',
  height: 'auto',
  backgroundColor: '#6554c0'
});

const sideBarStyle = css({
  width: '8px',
  height: 'auto'
});

const timeExpiredStyle = css({
  backgroundColor: '#00b8d9'
});

const meterExpiredStyle = css({
  backgroundColor: '#0265ff'
});
export default HistoryTickets;
