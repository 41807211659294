import React from 'react';
import { css } from 'emotion';
import EmptyImage from '../../assets/empty-image.svg';
function EmptyHistory(props) {
  return (
    <div className={divStyle}>
      <div className={subcontainer}>
        <img src={EmptyImage} alt="Empty History"></img>
      </div>
      <div className={header}>Empty History</div>
      <div className={subHeader}>No violations have been issued yet.</div>
    </div>
  );
}
const divStyle = css({
  backgroundColor: '#fafbfc',
  fontFamily: 'sans-serif',
  fontStyle: 'normal',
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
});
const subcontainer = css({
  margin: '0px 70px 0px 70px'
});
const header = css({
  fontSize: '18px',
  color: '#212121',
  marginTop: '12px'
});
const subHeader = css({
  marginTop: '8px',
  fontSize: '14px',
  color: '#757575'
});
export default EmptyHistory;
