import { USER_UNREGISTERED, NO_INTERNET } from '../constants/general';
import { isOnline } from '../utils/mobile';

import {
  USER_LOGGED_IN,
  USER_LOG_IN_REQESTED,
  USER_LOG_IN_ERROR,
  USER_LOGGED_OUT,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  RESET_INITIAL
} from '../constants/user';
import { postRequest, postRequestWithoutUser } from '../utils/api';

// const sha1 = require('crypto-js/sha1');

const initialState = {
  isLoggedIn: false,
  resetStatus: RESET_INITIAL,
  status: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGGED_IN:
      let payload = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        expiresAt: payload.expiresAt,
        name: payload.name,
        token: payload.token,
        status: USER_LOGGED_IN
      };
    case USER_LOG_IN_REQESTED:
      return {
        ...state,
        isLoggedIn: false,
        status: USER_LOG_IN_REQESTED
      };
    case USER_LOG_IN_ERROR:
      return {
        ...state,
        isLoggedIn: false,
        status: USER_LOG_IN_ERROR
      };
    case RESET_PASSWORD_REQUEST:
      return { ...state, resetStatus: RESET_PASSWORD_REQUEST };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetStatus: RESET_PASSWORD_SUCCESS,
        ...action.payload.user
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetStatus: RESET_PASSWORD_FAILED,
        error: action.payload.error
      };
    case RESET_INITIAL:
      return { ...state, resetStatus: RESET_INITIAL };
    default:
      return state;
  }
};

export const loginWithCreds = (userName, password) => {
  return dispatch => {
    dispatch({
      type: USER_LOG_IN_REQESTED
    });
    postRequestWithoutUser('login',{
      "user_email": userName,
      "password": password
    }).then(json => {
        let payload = {
            expiresAt: json.expires_at,
            name: json.display_name,
            token: json.token
        };
        window.localStorage.setItem('user', JSON.stringify(payload));
        dispatch({
          type: USER_LOGGED_IN,
          payload: payload
        });
    }).catch(error => {
      console.log(error);
      dispatch({ type: USER_LOG_IN_ERROR })
    });

  };
};

export function logout(informServer = true) {
  return (dispatch, getState) => {
    if (informServer) {
      postRequest('logout', null, getState().user)
        .then(response => {
          console.log(response);
        })
        .catch(error => console.log(error));
    }
    window.localStorage.clear();
    dispatch({
      type: USER_LOGGED_OUT
    });
  };
}

export function resetPassword(email_id) {
  return dispatch => {
    let isOffline = !isOnline();
    if (isOffline) {
      dispatch(networkDisconnectedAction);
      return;
    }

    dispatch(resetStatus(RESET_PASSWORD_REQUEST));

    postRequestWithoutUser('resetpassword', JSON.stringify({ email_id: email_id }))
      .then(json => dispatch(resetPasswordSuccess(json)))
      .catch(error => {
        let statusCode = error.message;
        if (statusCode === 401) {
          dispatch(resetPasswordFailure(USER_UNREGISTERED));
        } else {
          dispatch(resetPasswordFailure(statusCode));
        }
      });
  };
}

function resetPasswordSuccess(user) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: {
      user
    }
  };
}

function resetPasswordFailure(error) {
  return {
    type: RESET_PASSWORD_FAILED,
    payload: {
      error
    }
  };
}

export function resetStatus(status) {
  return { type: status };
}

let networkDisconnectedAction = { type: NO_INTERNET };
