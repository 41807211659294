import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '../textfield';
import Button from '../button';
import { css } from 'emotion';
import loading from '../../assets/loading.gif';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

function Login(props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [hidden, setHidden] = useState(true);
  let buttonStyle = activeButtonStyle;
  const toggleShow = () => {
    setHidden(!hidden);
  };
  const handleKeyPress = target => {
    if (target.charCode === 13) {
      props.onSubmit(username.toLowerCase(), password);
    }
  };
  const handleUnameCng = e => {
    setUsername(e.target.value);
    props.setloginError(false);
  };
  const handlePswdCng = e => {
    setPassword(e.target.value);
    props.setloginError(false);
  };
  const handleForgotPassword = () => {
    props.goToResetPassword();
  };
  return (
    <div className={divStyle}>
      <div className={textFields}>
        <TextField
          labelType="floating"
          label="Enter Username"
          value=""
          inputClass={inputFieldStyle}
          onChange={e => handleUnameCng(e)}
        />
        <hr className={hrStyle} />
        <div className={passwordStyle}>
          <TextField
            type={hidden ? 'password' : 'text'}
            labelType="floating"
            label="Enter Password"
            value=""
            inputClass={inputFieldStyle}
            onChange={e => handlePswdCng(e)}
            onKeyPress={handleKeyPress}
          />
          <div className={showbtnStyle} onClick={toggleShow}>
            {' '}
            {hidden ? 'SHOW' : 'HIDE'}
          </div>
        </div>
      </div>
      {props.loading ? (
        <img src={loading} alt="loading" className={loadingClass} />
      ) : (
        <Button
          btnClassName={buttonStyle}
          buttonClickCallback={() =>
            props.onSubmit(username.toLowerCase(), password)
          }
          text="Login"
        />
      )}
      <div className={forgotPasswordStyle} onClick={handleForgotPassword}>
        Forgot Password?
      </div>
    </div>
  );
}
const activeButtonStyle = css({
  width: '100%',
  marginTop: '20px'
});
// const btnStyle = css({
//   width: '100%',
//   marginTop: '20px',
//   backgroundColor: '#c1c7d0'
// });
const divStyle = css({
  textAlign: 'center',
  margin: '30px auto',
  padding: '0 16px 0 16px',
  maxWidth: '450px',
  fontSize: '14sp',
  fontFamily: 'sans-serif',
  fontStyle: 'normal',
  letterSpacing: 0.04,
  '::-webkit-input-placeholder': {
    color: '#bdbdbd'
  }
});
const textFields = css({
  border: 'solid 1px #dbdbdb',
  borderRadius: '2px'
});
const inputFieldStyle = css({
  border: 'none'
});

const hrStyle = css({
  margin: 0,
  border: 'none',
  borderBottom: 'solid 1px #dbdbdb'
});
const loadingClass = css({
  height: '50px',
  width: '50px'
});

const forgotPasswordStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '54px',
  fontSize: '16px',
  fontFamily: 'sans-serif',
  fontStyle: 'normal',
  color: '#757575',
  cursor: 'pointer',
  letterSpacing: 0.03
});

const passwordStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#ffffff'
});

const showbtnStyle = css({
  fontSize: '14px',
  fontFamily: 'sans-serif',
  fontStyle: 'normal',
  color: '#757575',
  paddingRight: '16px'
});
Login.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToResetPassword: () => push('/resetpassword')
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(Login);
