import React from 'react';
import { css } from 'emotion';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { logout } from '../../modules/user';

import backIcon from '../../assets/back-icon.svg';
import crossIcon from '../../assets/cross.svg';
import settingsIcon from '../../assets/settings-icon.svg';

function AppHeader(props) {
  return (
    <div>
      <div className={appHeaderClass}>
        {props.backIcon ? (
          <img
            className={imageStyle}
            onClick={props.handleBackButton}
            src={backIcon}
            alt="back icon"
          />
        ) : null}
        {props.crossIcon ? (
          <img
            className={imageStyle}
            onClick={props.handleCloseBtton}
            src={crossIcon}
            alt="close"
          />
        ) : null}
        <span>{props.title}</span>
        {props.settingsIcon ? (
          <div className={rightFloat}>
            <img
              className={settingsIconStyle}
              src={settingsIcon}
              alt="settings icon"
              onClick={props.handleSettingsButton}
            />
          </div>
        ) : null}
      </div>
      <div className={marginBar} />
    </div>
  );
}

const appHeaderClass = css({
  display: 'flex',
  background: '#112138',
  color: '#FFFFFF',
  position: 'fixed',
  padding: '20px',
  paddingTop: 'calc(20px + env(safe-area-inset-top))',
  fontSize: '18px',
  fontWeight: '500',
  top: '0',
  height: 'calc(60px + env(safe-area-inset-top))',
  width: '100vw',
  boxSizing: 'border-box',
  zIndex: '10'
});

const marginBar = css({
  height: 'calc(60px + env(safe-area-inset-top))',
  width: '100%'
});

const imageStyle = css({
  marginRight: '24px'
});
const settingsIconStyle = css({
  height: '16px',
  width: '16px'
});
const rightFloat = css({
  marginLeft: 'auto'
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(AppHeader);
