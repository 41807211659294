import React from 'react';
import { css } from 'emotion';

export default function MapLegend() {
  return (
    <div className={mapLegendClass}>
      <div className={flexStyle}>
        <span className={bubbleClass + ' ' + timedParkingBubbleStyle} />
        <span className={centerAlign}>Time Expired</span>
      </div>
    </div>
  );
}

const mapLegendClass = css({
  bottom: 'calc(env(safe-area-inset-bottom) + 10px)',
  left: '10px',
  borderRadius: '2px',
  position: 'absolute',
  backgroundColor: 'rgba(0,0,0,0.4)',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Roboto',
  fontWeight: 500,
  fontSize: '14px',
  height: '45px',
  pointerEvents: 'none',
  justifyContent: 'space-between',
  textTransform: 'capitalize',
  padding: '12px',
  color: '#ffffff'
});

const bubbleClass = css({
  borderRadius: '2px',
  width: '21px',
  height: '21px',
  display: 'inline-block',
  boxShadow: '0 2px 4px #00000080',
  marginRight: '8px'
});

const centerAlign = css({
  margin: 'auto 0'
});

const flexStyle = css({
  display: 'flex'
});

const noParkingBubbleStyle = css({
  backgroundColor: '#6554c0'
});

const timedParkingBubbleStyle = css({
  backgroundColor: '#ff7351'
});

const meterParkingBubbleStyle = css({
  backgroundColor: '#0265ff'
});

const multipleParkingBubbleStyle = css({
  backgroundColor: '#ff7351'
});
