import feature from 'turf-feature';

export function getPointFeaturesForSiteGroups(siteGroups) {
  let filteredSiteGroups = siteGroups.filter(siteGroup => siteGroup.center);
  return filteredSiteGroups.map(siteGroup => {
    let pointFeature = feature({
      type: 'Point',
      coordinates: siteGroup.center
    });
    pointFeature.properties = {
      ...siteGroup
    };

    return pointFeature;
  });
}

export function getParkingCircleStyles(
  sourceId,
  options = { minZoom: 15, maxZoom: 22 }
) {
  return [
    {
      id: 'parking_circles',
      type: 'circle',
      source: sourceId,
      paint: {
        'circle-radius': 0
      },
      minzoom: 0,
      maxzoom: 17
    }
  ];
}
