import moment from 'moment';

export function shouldRefreshToken(expiryTime) {
  if (Date.now() > new Date(expiryTime).getTime() * 1000) {
    return true;
  }
  return false;
}

export function getToken(user) {
  let token = user.token;
  return token;
}

export function isTokenValid(user) {
  if (user && user !== null && user.expiresAt !== null && user.token !== null) {
    let tokenExpiry = user.expiresAt;
    if (!tokenExpiry) {
      return false;
    }
    let expiryTime = moment.utc(tokenExpiry, 'YYYY-MM-DDTHH:mm:ssZ').local();
    let currentTimeInUTC = moment.utc().local();
    let isValid = expiryTime.isAfter(currentTimeInUTC);
    return isValid;
  }
  return false;
}
