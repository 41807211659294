import React from 'react';

import { css } from 'emotion';

export default function Dialog(props) {
  return (
    <div className={backgroundFade}>
      <div className={dialogBox}>
        <div className={dialogBoxHeading}>{props.DialogHeading}</div>
        <div className={dialogBoxText}>{props.DialogText}</div>
        <button
          className={dialogBoxButton1}
          onClick={() => props.Button1FuncCallback()}
        >
          {props.Button1Text}
        </button>
        <button
          className={dialogBoxButton2}
          onClick={() => props.Button2FuncCallback()}
        >
          {props.Button2Text}
        </button>
      </div>
    </div>
  );
}

const backgroundFade = css({
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0,0,0,0.6)',
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  zIndex: 100
});

const dialogBox = css({
  backgroundColor: '#FFFFFF',
  width: '300px',
  height: '180px',
  verticalAlign: 'middle',
  boxShadow: '0 10px 10px 5px rgba(0,0,0,0.14)',
  margin: 'auto',
  borderRadius: '5px'
});

const dialogBoxHeading = css({
  fontSize: '22px',
  padding: '20px',
  fontWeight: 400
});

const dialogBoxText = css({
  padding: '20px',
  paddingTop: 0,
  color: '#757575'
});

const dialogBoxButton1 = css({
  height: '60px',
  width: '50%',
  backgroundColor: '#f9f9f9',
  border: 0,
  color: '#757575',
  borderTop: '1px solid rgba(0, 0, 0, 0.08)',
  borderBottomLeftRadius: '5px'
});
const dialogBoxButton2 = css({
  height: '60px',
  width: '50%',
  backgroundColor: '#f9f9f9',
  border: 0,
  color: '#0152cc',
  borderTop: '1px solid rgba(0, 0, 0, 0.08)',
  borderLeft: '1px solid rgba(0, 0, 0, 0.08)',
  borderBottomRightRadius: '5px'
});
