import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import user from './user';
import deployments from './deployments';
import map from './map';
import ticket from './ticket';
import textfield from './textfield';

const createAppReducer = history =>
  combineReducers({
    router: connectRouter(history),
    user,
    deployments,
    map,
    ticket,
    textfield
  });

const createRootReducer = history => (state, action) => {
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined;
  }

  return createAppReducer(history)(state, action);
};

export default createRootReducer;
