import {
  ADD_VIOLATION,
  INITIALIZED_TICKET,
  VERIFIED_VEHICLE,
  VEHICLE_DETAILS,
  UPLOAD_IMAGE_REQUESTED,
  UPLOAD_IMAGE_RECIEVED,
  IMAGE_UPLOAD_FAILURE,
  GENERATE_TICKET_REQUESTED,
  GENERATE_TICKET_RECIEVED,
  GENERATE_TICKET_FAILURE,
  SAVE_END_TIME,
  TICKET_HISTORY_REQUESTED,
  TICKET_HISTORY_RECIEVED,
  TICKET_HISTORY_FAILURE,
  NO_PARKING,
  METER_EXPIRED,
  TIME_EXPIRED,
  RESET_HISTORY
} from '../constants/ticket';
import { postEnforceRequest, getEnforceRequest } from '../utils/api';
import moment from 'moment-timezone';

const initialState = {
  ticketStatus: null,
  photoStatus: null,
  ticketHistoryStatus: null,
  ticketDetails: {}
};
export default (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZED_TICKET:
      return {
        ...state,
        ticketDetails: action.payload.spaceDetails,
        ticketStatus: INITIALIZED_TICKET
      };
    case ADD_VIOLATION:
      let violationTypes = action.payload.violations;
      let tempObj = { ...state.ticketDetails };
      tempObj.violations = violationTypes;
      return {
        ...state,
        ticketStatus: ADD_VIOLATION,
        ticketDetails: tempObj
      };
    case VERIFIED_VEHICLE:
      let verificationObj = {};
      verificationObj = { ...state.ticketDetails };
      verificationObj['licensePlateNumber'] = action.payload.lpNum;
      verificationObj['verificationIdentificationNumber'] =
        action.payload.vinNum;
      return {
        ...state,
        ticketDetails: verificationObj
      };
    case VEHICLE_DETAILS:
      let vehicleDetailsObj = {};
      vehicleDetailsObj = { ...state.ticketDetails };
      vehicleDetailsObj['vehicleManufacturer'] = action.payload.manufacturer;
      vehicleDetailsObj['vehicleType'] = action.payload.type;
      vehicleDetailsObj['vehicleColor'] = action.payload.color;
      return {
        ...state,
        ticketDetails: vehicleDetailsObj
      };
    case UPLOAD_IMAGE_REQUESTED:
      return {
        ...state,
        photoStatus: UPLOAD_IMAGE_REQUESTED
      };
    case UPLOAD_IMAGE_RECIEVED:
      return {
        ...state,
        photoStatus: UPLOAD_IMAGE_RECIEVED,
        photoId: action.payload.json.id
      };
    case IMAGE_UPLOAD_FAILURE:
      return {
        ...state,
        photoStatus: IMAGE_UPLOAD_FAILURE,
        error: action.payload.error
      };
    case GENERATE_TICKET_REQUESTED:
      return {
        ...state,
        ticketStatus: GENERATE_TICKET_REQUESTED
      };
    case GENERATE_TICKET_RECIEVED:
      let ticketObj = {};
      ticketObj = { ...state.ticketDetails };
      ticketObj['ticketId'] = action.payload;
      return {
        ...state,
        ticketStatus: GENERATE_TICKET_RECIEVED,
        ticketDetails: ticketObj
      };
    case GENERATE_TICKET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        ticketStatus: GENERATE_TICKET_FAILURE
      };
    case SAVE_END_TIME:
      let endTime = action.payload.endTime;
      let ticketEndTimeObj = {};
      ticketEndTimeObj = { ...state.ticketDetails };
      ticketEndTimeObj['endTime'] = endTime;
      return {
        ...state,
        ticketStatus: SAVE_END_TIME,
        ticketDetails: ticketEndTimeObj
      };
    case TICKET_HISTORY_REQUESTED:
      return {
        ...state,
        ticketHistoryStatus: TICKET_HISTORY_REQUESTED
      };
    case TICKET_HISTORY_RECIEVED:
      return {
        ...state,
        ticketHistoryStatus: TICKET_HISTORY_RECIEVED,
        history: action.payload.json
      };
    case TICKET_HISTORY_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        ticketHistoryStatus: TICKET_HISTORY_FAILURE
      };
    case RESET_HISTORY:
      return {
        ...state,
        ticketHistoryStatus: RESET_HISTORY,
        history: undefined
      };
    default:
      return state;
  }
};
export function resetTicketHistory() {
  return { type: RESET_HISTORY };
}
export function createTicket(spaceDetails) {
  return dispatch => {
    dispatch(ticketInitialization(spaceDetails));
  };
}

export function ticketInitialization(spaceDetails) {
  return { type: INITIALIZED_TICKET, payload: { spaceDetails } };
}
export function addViolation(violations) {
  return dispatch => {
    dispatch(violationsAdded(violations));
  };
}
export function uploadImage(imgSrc) {
  return (dispatch, getState) => {
    let data = {};
    let siteId = getState().ticket.ticketDetails.id;
    data['site'] = siteId;
    data['upload'] = imgSrc;
    dispatch(uploadImageRequested());
    postEnforceRequest('imageUpload', data, getState().user)
      .then(json => dispatch(UploadImageReceived(json)))
      .catch(error => {
        let statusCode = error.message;
        if (statusCode === 401) {
          dispatch(uploadImgFialure(IMAGE_UPLOAD_FAILURE));
        } else {
          dispatch(uploadImgFialure(statusCode));
        }
      });
  };
}
export function generateTicket(sessionID, startTime) {
  return (dispatch, getState) => {
    let ticketDetails = getState().ticket.ticketDetails;
    let violations = [];
    ticketDetails.violations.forEach(vio => {
      if (vio['type'] === 0) {
        violations.push(NO_PARKING);
      }
      if (vio['type'] === 1) {
        violations.push(METER_EXPIRED);
      }
      if (vio['type'] === 2) {
        violations.push(TIME_EXPIRED);
      }
    });
    if (ticketDetails.photoId !== undefined) {
      var photoID;
      photoID = ticketDetails.photoId;
    }
    let endTime = moment
      .tz(ticketDetails.geojson.properties['time-zone'])
      .format('YYYY-MM-DD HH:mm:ss');
    let params = {
      session_id: sessionID,
      start_time: startTime,
      violations: ticketDetails.violations,
      comment: '',
      space_name: ticketDetails.name,
      action_taken: 'cited',
      details: JSON.stringify({
        start_time: startTime,
        end_time: endTime,
        violation_type: violations.join(', '),
        comment: '',
        space_name: ticketDetails.name,
        action_taken: 'cited',
        photoId: photoID
      })
    };
    dispatch(generateTicketRequested());
    dispatch(saveEndTime(endTime));
    postEnforceRequest('tickets', params, getState().user)
      .then(json => dispatch(generateTicketRecieved(json)))
      .catch(error => {
        let statusCode = error.message;
        if (statusCode === 401) {
          dispatch(generateTicketFailure(GENERATE_TICKET_FAILURE));
        } else {
          dispatch(generateTicketFailure(statusCode));
        }
      });
  };
}
export function getTicketsHistory() {
  return (dispatch, getState) => {
    dispatch(ticketHistoryRequested());
    getEnforceRequest('tickets', getState().user)
      .then(json => dispatch(ticketHistoryRecieved(json)))
      .catch(error => {
        let statusCode = error.message;
        if (statusCode === 401) {
          dispatch(ticketHistoryFailure(TICKET_HISTORY_FAILURE));
        } else {
          dispatch(ticketHistoryFailure(statusCode));
        }
      });
  };
}

export function violationsAdded(violations) {
  return { type: ADD_VIOLATION, payload: { violations } };
}
export function verifyVehicle(lpNum, vinNum) {
  return { type: VERIFIED_VEHICLE, payload: { lpNum, vinNum } };
}
export function vehicleDetails(manufacturer, type, color) {
  return { type: VEHICLE_DETAILS, payload: { manufacturer, type, color } };
}
export function uploadImageRequested() {
  return { type: UPLOAD_IMAGE_REQUESTED };
}
export function UploadImageReceived(json) {
  return { type: UPLOAD_IMAGE_RECIEVED, payload: { json } };
}
export function uploadImgFialure(error) {
  return { type: IMAGE_UPLOAD_FAILURE, payload: { error } };
}
export function generateTicketRequested() {
  return { type: GENERATE_TICKET_REQUESTED };
}
export function generateTicketRecieved(json) {
  return { type: GENERATE_TICKET_RECIEVED, payload: json.id };
}
export function generateTicketFailure(error) {
  return { type: GENERATE_TICKET_FAILURE, payload: error };
}
export function saveEndTime(endTime) {
  return { type: SAVE_END_TIME, payload: { endTime } };
}
export function ticketHistoryRequested() {
  return { type: TICKET_HISTORY_REQUESTED };
}
export function ticketHistoryRecieved(json) {
  return { type: TICKET_HISTORY_RECIEVED, payload: { json } };
}
export function ticketHistoryFailure(error) {
  return { type: TICKET_HISTORY_FAILURE, payload: error };
}
