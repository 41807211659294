/* global device */
import React, { useState } from 'react';
import classnames from 'classnames';
import { css } from 'emotion';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  setScrollTimeoutId,
  clearScrollTimeoutId
} from '../../modules/textfield';
import { isInAppEnv } from '../../utils/mobile';

function TextInput(props) {
  const [text, setText] = useState('');
  const [focus, setFocus] = useState(0);

  let {
    type = 'text',
    placeholder,
    label,
    labelType = 'static',
    value = '',
    spacing = 0,
    className,
    required = false,
    onKeyPress = () => {},
    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {}
  } = props;

  function addSpacesToTextAtInterval(text, spacing) {
    let formattedText = text.split(' ').join('');
    if (formattedText.length > 0) {
      formattedText = formattedText
        .match(new RegExp(`.{1,${spacing}}`, 'g'))
        .join(' ');
    }
    return formattedText;
  }

  const handleOnChange = e => {
    const text = e.target.value;
    if (spacing > 0) {
      setText(addSpacesToTextAtInterval(text, spacing));
    } else {
      setText(text);
    }
    onChange(e, e.target.value);
  };

  const handleOnBlur = e => {
    setFocus(0);
    if (isInAppEnv() && device.platform === 'iOS') {
      props.setScrollTimeoutId(setTimeout(() => window.scrollTo(0, 0), 100));
    }
    onBlur(e);
  };

  const handleOnFocus = e => {
    setFocus(1);
    if (
      isInAppEnv() &&
      device.platform === 'iOS' &&
      props.textfield.timeoutId
    ) {
      clearTimeout(props.textfield.timeoutId);
      props.clearScrollTimeoutId(props.textfield.timeoutId);
    }
    onFocus(e);
  };

  let textValue = text !== '' ? text : value;

  let classes = classnames({
    [`${textInput}`]: true,
    [className]: !!className,
    'floating-label': labelType === 'floating',
    'floating-label-active': labelType === 'floating' && focus
  });
  let labelClass = classnames({
    [`${textInputLabel}`]: true,
    [`${floatingLabel}`]: labelType === 'floating',
    [`${floatingLabelActive}`]: labelType === 'floating' && (focus || textValue)
  });
  return (
    <label className={classes}>
      {labelType === 'static' ? (
        <span className={labelClass}>{label}</span>
      ) : null}
      <input
        type={type}
        value={textValue}
        ref={props.refs}
        required={required}
        onKeyPress={onKeyPress}
        placeholder={labelType !== 'floating' ? placeholder : ''}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        onChange={handleOnChange}
        className={inputClass + ' ' + props.inputClass}
      />
      {labelType === 'floating' ? (
        <span className={labelClass}>{label}</span>
      ) : null}
    </label>
  );
}

const mapStateToProps = state => ({
  textfield: state.textfield
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setScrollTimeoutId,
      clearScrollTimeoutId
    },
    dispatch
  );

const textInput = css({
  position: 'relative',
  display: 'block',
  height: '52px',
  width: '100%',
  textAlign: 'left'
});
const textInputLabel = css({
  display: 'inline-block',
  marginBottom: '8px',
  fontSize: '12px'
});
const floatingLabel = css({
  position: 'absolute',
  left: 0,
  top: 0,
  padding: '17px 12px',
  margin: 0,
  fontSize: '14px',
  color: 'rgba(0,0,0,0.27)',
  transform: 'scale(1)',
  transition: 'transform .3s, transform-orign .5s',
  transformOrigin: '12px -45%'
});
const floatingLabelActive = css({
  transform: 'scale(0.7)',
  color: '#0152cc'
});

const inputClass = css({
  width: '100%',
  height: '52px',
  boxSizing: 'border-box',
  fontSize: '12px',
  padding: '10px 12px',
  background: '#FFFFFF',
  border: 'solid 1px #dbdbdb',
  borderRadius: '2px',

  '&:focus': {
    outline: 'none'
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TextInput);
