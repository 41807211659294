export const API_ROOT = 'https://api.enforce.fybr-tech.com/api/';
// export const API_ROOT = 'http://127.0.0.1:3000/api/';
export const SERVER_DOWN = 'SERVER_DOWN';
export const USER_UNREGISTERED = 'USER_EXISTS';
export const USER_UNREGISTERED_MSG = 'User not registered to use enforce.';
export const NO_INTERNET = 'NO_INTERNET';
export const FIELD_EMPTY = 'FIELD_EMPTY';
export const SERVICE_TEMPORARILY_DOWN_MSG =
  'Service is temporarily down. Please try again later.';
export const ENTER_ALL_FIELDS = 'Please enter all fields.';
