/*global launchnavigator*/

export function isInAppEnv() {
  // let isApp =
  //   document.URL.indexOf('http://') === -1 &&
  //   document.URL.indexOf('https://') === -1;
  // return isApp;
	return window.cordova ? window.cordova.platformId : false;
}

// dependent on cordova-plugin-network-information
export function isOnline() {
  let isOnline = isInAppEnv()
    ? navigator.connection.type !== 'none'
    : navigator.onLine;
  return isOnline;
}

export function navigateTo(coords) {
  console.log('navigate to ' + coords);
  let intentType = launchnavigator.APP.GEO;
  launchnavigator.navigate(
    coords,
    function success() {
      console.log('Launched Navigation: ');
    },
    function error(e) {
      console.error('Error launching navigation: ' + e);
    },
    {
      app: intentType
    }
  );
}
