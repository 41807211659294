import {
  FETCH_CITIES_REQUESTED,
  FETCH_CITIES_RECEIVED,
  SELECT_CITY
} from '../constants/sites';
import {
  FETCH_1X_VIOLATIONS_REQUESTED,
  FETCH_1X_VIOLATIONS_RECEIVED
} from '../constants/map';
import { getRequest, getEnforceRequest } from '../utils/api';

const initialState = {
  cities: [],
  citiesStatus: null,
  oneXViolationStatus: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CITIES_REQUESTED:
      return {
        ...state,
        citiesStatus: FETCH_CITIES_REQUESTED
      };
    case FETCH_CITIES_RECEIVED:
      let { citiesJson } = action.payload;
      let addSelectedKeyCitiesJson = citiesJson.map(city => {
        return { ...city, selected: false, total_violations: null };
      });
      return {
        ...state,
        cities: addSelectedKeyCitiesJson,
        citiesStatus: FETCH_CITIES_RECEIVED
      };
    case FETCH_1X_VIOLATIONS_REQUESTED:
      return {
        ...state,
        oneXViolationStatus: FETCH_1X_VIOLATIONS_REQUESTED
      };
    case FETCH_1X_VIOLATIONS_RECEIVED:
      let { districts_in_violation } = action.payload.sitesJson;
      let { cities } = state;
      let addViolationCount = cities.map(city => {
        let violatingDistrictIndex = districts_in_violation.findIndex(
          district => {
            return district.district_id === parseInt(city.district_id);
          }
        );
        city.total_violations = 0;
        if (violatingDistrictIndex !== -1) {
          city.total_violations =
            districts_in_violation[violatingDistrictIndex].total_violations;
        }
        return city;
      });
      return {
        ...state,
        cities: addViolationCount,
        oneXViolationStatus: FETCH_1X_VIOLATIONS_RECEIVED
      };
    case SELECT_CITY:
      let { cities: citiesState } = state;
      let resetAllSelection = citiesState.map(city => {
        return { ...city, selected: false };
      });
      resetAllSelection[action.payload.index].selected = true;
      return {
        ...state,
        cities: resetAllSelection
      };
    default:
      return state;
  }
};

export function getCities() {
  return (dispatch, getState) => {
    dispatch(citiesRequested());
    getRequest('cities', getState().user)
      .then(json => {
        dispatch(citiesReceived(json));
      })
      .catch(error => console.log(error));
  };
}

export function getSitesViolations1xLevel() {
  return (dispatch, getState) => {
    dispatch(violations1xRequested());
    getEnforceRequest(
      'sites?top_lat=85&bottom_lat=-85&left_lon=-180&right_lon=180&attributes=violation&zoom_level=1',
      getState().user
    )
      .then(json => dispatch(violations1xReceived(json)))
      .catch(error => console.log(error));
  };
}

export function violations1xRequested() {
  return { type: FETCH_1X_VIOLATIONS_REQUESTED };
}

export function violations1xReceived(sitesJson) {
  return { type: FETCH_1X_VIOLATIONS_RECEIVED, payload: { sitesJson } };
}

export function citiesRequested() {
  return { type: FETCH_CITIES_REQUESTED };
}

export function citiesReceived(citiesJson) {
  return { type: FETCH_CITIES_RECEIVED, payload: { citiesJson } };
}
export function selectCity(index) {
  return { type: SELECT_CITY, payload: { index } };
}
