import React, { useState, useEffect } from 'react';
import LoginError from '../login/loginerror';
import AppHeader from '../app-header';
import TextField from '../textfield';
import Button from '../button';
import Dialog from '../dialog';

import { css } from 'emotion';
import loading from '../../assets/loading.gif';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { goBack } from 'connected-react-router';

import { resetPassword } from '../../modules/user';

import {
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS
} from '../../constants/user';

import {
  SERVER_DOWN,
  SERVICE_TEMPORARILY_DOWN_MSG,
  USER_UNREGISTERED,
  USER_UNREGISTERED_MSG,
  FIELD_EMPTY
} from '../../constants/general';

function ResetPassword(props) {
  const [email_id, setEmail] = useState('');
  const [resetStatus, setResetStatus] = useState(props.user.resetStatus);
  const [error, setError] = useState(props.user.error);

  const onResetPassword = email_id => {
    if (email_id !== '') {
      props.resetPassword(email_id.toLowerCase().trim());
    } else {
      setResetStatus(FIELD_EMPTY);
    }
  };

  const handleKeyPress = target => {
    if (target.charCode === 13) {
      onResetPassword(email_id);
    }
  };

  const onClickBack = () => {
    props.historyPush('/login');
  };

  const renderError = () => {
    if (resetStatus === RESET_PASSWORD_FAILED) {
      if (error === SERVER_DOWN) {
        return <LoginError content={SERVICE_TEMPORARILY_DOWN_MSG} />;
      } else if (error === USER_UNREGISTERED) {
        return <LoginError content={USER_UNREGISTERED_MSG} />;
      }
      return <LoginError content={'Unable to send mail. Try Again later'} />;
    } else if (resetStatus === FIELD_EMPTY) {
      return <LoginError content="Please enter all the fields." />;
    }
    return null;
  };

  const renderDialog = () => {
    if (resetStatus === RESET_PASSWORD_SUCCESS) {
      return (
        <Dialog
          DialogHeading="Email Sent"
          DialogText="Check your inbox for an email from Fybr with a link to reset your
            password."
          Button1Text="RESEND"
          Button2Text="OKAY"
          Button1FuncCallback={() => onResetPassword(email_id)}
          Button2FuncCallback={() => onClickBack()}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    if (props.user.resetStatus === RESET_PASSWORD_SUCCESS) {
      setResetStatus(props.user.resetStatus);
    } else {
      setResetStatus(props.user.resetStatus);
      setError(props.user.error);
    }
  }, [props.user.resetStatus, props.user.error]);

  return (
    <div className={container}>
      <AppHeader backIcon title="Reset Password" handleBackButton={props.prevPage}/>
      {renderError()}
      {renderDialog()}
      <div className={divStyle}>
        <div className={textFields}>
          <TextField
            labelType="floating"
            label="Enter Email"
            value=""
            inputClass={inputFieldStyle}
            onChange={(event, newValue) => setEmail(newValue)}
            onKeyPress={handleKeyPress}
          />
        </div>
        {resetStatus === RESET_PASSWORD_REQUEST ? (
          <img src={loading} alt="loading" className={loadingClass} />
        ) : (
          <Button
            label="Reset"
            btnClassName={buttonStyle}
            buttonClickCallback={() => onResetPassword(email_id)}
            text="RESET"
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetPassword,
      historyPush: route => push(route),
      prevPage: () => goBack()
    },
    dispatch
  );

const container = css({
  backgroundColor: '#F8F8F8',
  height: '100vh'
});

const buttonStyle = css({
  width: '100%',
  marginTop: '20px'
});
const divStyle = css({
  textAlign: 'center',
  margin: '30px auto',
  maxWidth: '450px'
});
const textFields = css({
  border: 'solid 1px #dbdbdb',
  borderRadius: '2px'
});
const inputFieldStyle = css({
  border: 'none'
});

const loadingClass = css({
  height: '50px',
  width: '50px'
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
