import {
  SET_SCROLL_TIMEOUT_ID,
  CLEAR_SCROLL_TIMEOUT_ID
} from '../constants/textfield';

const initialState = {
  timeoutId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SCROLL_TIMEOUT_ID:
      return {
        ...state,
        timeoutId: action.payload.timeoutId
      };
    case CLEAR_SCROLL_TIMEOUT_ID:
      return {
        ...state,
        timeoutId: null
      };
    default:
      return state;
  }
};

export function setScrollTimeoutId(timeoutId) {
  return dispatch =>
    dispatch({
      type: SET_SCROLL_TIMEOUT_ID,
      payload: {
        timeoutId
      }
    });
}

export function clearScrollTimeoutId() {
  return dispatch =>
    dispatch({
      type: CLEAR_SCROLL_TIMEOUT_ID
    });
}
