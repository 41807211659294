import React from 'react';
import { css } from 'emotion';
import moment from 'moment-timezone';
import {
  NO_PARKING,
  METER_EXPIRED,
  TIME_EXPIRED
} from '../../constants/ticket';
function HistoryTicket(props) {
  if (!props.action_taken.length || !props.violations) {
    return null;
  }
  let violationType = [];
  if (props.violations !== undefined) {
    props.violations.forEach(vio => {
      if (vio['type'] === 0) {
        violationType.push(NO_PARKING);
      }
      if (vio['type'] === 2) {
        violationType.push(TIME_EXPIRED);
      }
      if (vio['type'] === 1) {
        violationType.push(METER_EXPIRED);
      }
    });
  }

  if (violationType.length === 0) return null;
  let createdAt = moment(props.created_at)
    .local()
    .format('DD MMM YYYY');

  // Since created_at is string so first we have to form a mement object
  // And then we have to convert it to local timezone
  let time = moment(moment.tz(props.created_at, props.time_zone))
    .tz(moment.tz.guess())
    .format('hh:mm a z');

  return (
    <div className={container}>
      <div className={subContainer}>
        <div className={props.colorStyle}></div>
        <div className={wrapContainer}>
          <div className={vioContainer}>
            {violationType !== undefined ? (
              <span> {violationType.join(', ')} </span>
            ) : null}
          </div>
          <div className={rowStyle}>
            <div className={headerStyle}>
              <div className={titleStyle}>Space ID</div>
              <div className={titleStyle}>Action Taken</div>
              <div className={titleStyle}>Action ID</div>
            </div>
            <div className={dataStyle}>
              <div className={titleStyle}>
                {props.site_id !== undefined ? props.site_id : '-'}
              </div>
              <div className={titleStyle}>{props.action_taken}</div>
              <div className={titleStyle}>{props.ticket_id}</div>
            </div>
          </div>
          <div className={rowStyle}>
            <div className={headerStyle}>
              <div className={titleStyle}>Ticket Issue Date</div>
              <div className={titleStyle}>Ticket Issue Time</div>
            </div>
            <div className={dataStyle}>
              <div className={titleStyle}>{createdAt}</div>
              <div className={timestampStyle}>{time}</div>
            </div>
            {props.comment ? (
              <div className={commentStyle}>{props.comment}</div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
const rowStyle = css({
  display: 'flex',
  flexDirection: 'column'
});
const titleStyle = css({
  display: 'flex',
  width: '33%',
  textTransform: 'capitalize'
});
const timestampStyle = css({
  display: 'flex',
  width: '33%'
});
const headerStyle = css({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  marginTop: '16px',
  fontSize: '12px',
  fontFamily: 'sans-serif',
  fontStyle: 'normal',
  color: '#bdbdbd'
});
const dataStyle = css({
  display: 'flex',
  alignItems: 'center',
  color: '#000000',
  fontSize: '12px'
});
const commentStyle = css({
  display: 'flex',
  flexDirection: 'column',
  margin: '16px 0 0 24px',
  color: '#757575',
  fontSize: '14px'
});
const container = css({
  backgroundColor: '#ffffff',
  border: '1px solid #1e000000',
  borderRadius: '1px',
  boxShadow: '2px 2px 2px 2px #f2f2f2',
  margin: '8px'
});
const subContainer = css({
  display: 'flex'
});
const wrapContainer = css({
  width: '100%',
  marginTop: '12px',
  marginLeft: '16px',
  marginRight: '16px',
  marginBottom: '16px'
});
const vioContainer = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: '18px',
  fontFamily: 'sans-serif',
  fontStyle: 'normal',
  color: '#212121'
});
export default HistoryTicket;
