// import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { css } from 'emotion';
import centroid from '@turf/centroid';

import {
	getFeatures,
	geojsonSource,
	getParkingSpaceStyles,
	getSpaceNameTextStyle
} from './rect-feature';
import { LAYER_4X_ZOOM_RANGE } from '../../constants/map';
import { addLayer } from './utils';
import {
  getParkingCircleStyles,
  getPointFeaturesForSiteGroups
} from './circle-feature';

// export function fourXLayer(props) {
//   const map = props.map;
// }

const colors = ['#ff7351', '#ff7351', '#ff7351']; //['#00b8d9', '#6554c0'];

export function drawSpaces(sites, map, siteId) {
  let features = getFeatures(sites, siteId);
  let sourceId = 'street-rectangular-spaces';
  let addStyleLayersToParkingSpaces = geojsonSource(sourceId, features);
  let parkingSpacesLayer = addStyleLayersToParkingSpaces(
    getParkingSpaceStyles(sourceId, {
      minZoom: LAYER_4X_ZOOM_RANGE[0],
      maxZoom: LAYER_4X_ZOOM_RANGE[1]
    })
  );
  addLayer(parkingSpacesLayer, map);

  let addStylesToSpaceNamesText = geojsonSource(sourceId + '-text', features.map(f => {
	  let fCentroid = centroid(f);
	  fCentroid.properties = f.properties;
	  return fCentroid;
  }));

  let spaceNameTextLayer = addStylesToSpaceNamesText([
	  getSpaceNameTextStyle(
		  sourceId + '-text',
		  '#ffffff',
		  LAYER_4X_ZOOM_RANGE[0],
		  LAYER_4X_ZOOM_RANGE[1]
	  )
  ]);

  addLayer(spaceNameTextLayer, map);
}

export function drawCircles(sites, map) {
  let features = getPointFeaturesForSiteGroups(sites);
  let sourceId = 'street_circles';
  let addStyleLayersToParkingSpaces = geojsonSource(sourceId, features);
  let parkingSpacesLayer = addStyleLayersToParkingSpaces(
    getParkingCircleStyles(sourceId, {
      minZoom: LAYER_4X_ZOOM_RANGE[0],
      maxZoom: LAYER_4X_ZOOM_RANGE[1]
    })
  );
  addLayer(parkingSpacesLayer, map);
}

export function updateMarkers(map, markers, markersOnScreen) {
  let newMarkers = {};
  let features = map.querySourceFeatures('street_circles');
  // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
  // and add it to the map if it's  not there already
  for (let i = 0; i < features.length; i++) {
    let coords = features[i].geometry.coordinates;
    let props = features[i].properties;
    let id = props.street_block_group_id;

    let marker = markers[id];
    if (id) {
      let el = createDonutChart(props);

      el.addEventListener('click', () => {
        map.flyTo({
          // These options control the ending camera position: centered at
          // the target, at zoom level 9, and north up.
          center: coords,
          zoom: 18.5,
          bearing: 0,

          // These options control the flight curve, making it move
          // slowly and zoom out almost completely before starting
          // to pan.
          speed: 0.5
        });
      });
      if (markers[id]) {
        markers[id].remove();
      }
      marker = markers[id] = new mapboxgl.Marker({
        element: el
      }).setLngLat(coords);
    }
    newMarkers[id] = marker;
    marker.addTo(map);
  }
  // for every marker we've added previously, remove those that are no longer visible

  for (let id in markersOnScreen) {
    if (!newMarkers[id]) {
      markersOnScreen[id].remove();
    }
  }
  markersOnScreen = newMarkers;
  return [markers, markersOnScreen];
}

// code for creating an SVG donut chart from feature properties
function createDonutChart(props) {
  var offsets = [];
  var counts = [
    props.num_timed_parking_violation,
    props.num_no_parking_violation,
	  props.num_paid_parking_violation
  ];
  var total = 0;
  for (var i = 0; i < counts.length; i++) {
    offsets.push(total);
    total += counts[i];
  }
  var fontSize = total >= 1000 ? 18 : total >= 100 ? 16 : total >= 10 ? 14 : 12;
  var r = total >= 1000 ? 54 : total >= 100 ? 36 : total >= 10 ? 28 : 22;
  var r0 = Math.round(r * 0.75);
  var w = r * 2;

  var html =
    '<svg width="' +
    w +
    '" height="' +
    w +
    '" viewbox="0 0 ' +
    w +
    ' ' +
    w +
    '" text-anchor="middle" style="font: ' +
    fontSize +
    'px sans-serif">';
  if (total > 0) {
    for (i = 0; i < counts.length; i++) {
      html += donutSegment(
        offsets[i] / total,
        (offsets[i] + counts[i]) / total,
        r,
        r0,
        colors[i]
      );
    }
  }
  html +=
    total > 0
      ? '<circle cx="' +
        r +
        '" cy="' +
        r +
        '" r="' +
        r0 +
        '" fill="white" /><text dominant-baseline="central" font-family="Poppins" font-weight="500" transform="translate(' +
        r +
        ', ' +
        r +
        ')">' +
        total.toLocaleString().padStart(2, '0') +
        '</text></svg>'
      : '';

  var el = document.createElement('div');
  el.innerHTML = html;
  return el.firstChild;
}

function donutSegment(start, end, r, r0, color) {
  if (end - start === 1) end -= 0.00001;
  var a0 = 2 * Math.PI * (start - 0.25);
  var a1 = 2 * Math.PI * (end - 0.25);
  var x0 = Math.cos(a0),
    y0 = Math.sin(a0);
  var x1 = Math.cos(a1),
    y1 = Math.sin(a1);
  var largeArc = end - start > 0.5 ? 1 : 0;

  return [
    '<path d="M',
    r + r0 * x0,
    r + r0 * y0,
    'L',
    r + r * x0,
    r + r * y0,
    'A',
    r,
    r,
    0,
    largeArc,
    1,
    r + r * x1,
    r + r * y1,
    'L',
    r + r0 * x1,
    r + r0 * y1,
    'A',
    r0,
    r0,
    0,
    largeArc,
    0,
    r + r0 * x0,
    r + r0 * y0,
    '" fill="' + color + '" />'
  ].join(' ');
}

function pad(num, size) {
  if (num === 0) return 0;
  let s = num + '';
  while (s.length < size) s = '0' + s;
  return s;
}

export function getSiteDetailsHTML(totalViolations, siteName) {
  const violationsStyle = css({
    display: 'flex',
    borderRadius: '50%',
    height: '35px',
    width: '35px',
    background: '#c1c7d0',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    position: 'relative',
    left: '-3px'
  });
  const siteNameStyle = css({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    paddingLeft: '10px',
    paddingRight: '10px'
  });
  const arrowStyle = css`
    display: flex;
    flexdirection: column;
    textalign: center;
    height: 10px;
    width: 10px;
    padding-right: 10px;
    i {
      border: solid #c1c7d0;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
    }
  `;

  const violationsTextStyle = css({
    color: '#ffffff',
    fontSize: '20px',
    fontFamily: 'sans-serif',
    fontStyle: 'normal'
  });
  const textStyle = css({
    color: '#000000',
    fontSize: '20px',
    fontFamily: 'sans-serif',
    fontStyle: 'normal'
  });

  const right = css({
    transform: 'rotate(-45deg)'
  });

  const paddedViolationsNum = pad(totalViolations, 2);
  const html =
    `<div class=${violationsStyle}><span class=${violationsTextStyle}>${paddedViolationsNum}</span></div>` +
    `<div class=${siteNameStyle}><span class=${textStyle}>${siteName}</span></div>` +
    `<div class=${arrowStyle}><i class=${right}></i></div>`;
  return html;
}
