import React from 'react';
import { css } from 'emotion';

import errorImg from '../../assets/error-icon.svg';

const defaultContent = 'Your login attempt has failed. Try again';
function LoginError(props) {
  return (
    <div className={loginError}>
      {props.children && props.children.length > 0 ? (
        props.children
      ) : (
        <div className={flex}>
          <img src={errorImg} alt="" className={marginRight} />
          <span>{props.content || defaultContent}</span>
        </div>
      )}
    </div>
  );
}

const loginError = css({
  background: '#FFFFFF',
  boxShadow: '0 1px 4px 0 rgba(0,0,0,0.12)',
  margin: '0px',
  display: 'flex',
  marginBottom: '10px',
  fontSize: '14px',
  letterSpacing: '0.5px',
  width: '100%',
  boxSizing: 'border-box',
  color: '#757575',
  lineHeight: '18px',
  padding: '15px'
});
const marginRight = css({
  marginRight: '10px'
});
const flex = css({
  display: 'flex'
});

export default LoginError;
