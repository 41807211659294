import {
  FETCH_SITES_REQUESTED,
  FETCH_SITES_RECEIVED,
  FETCH_VIOLATIONS_REQUESTED,
  FETCH_VIOLATIONS_RECEIVED,
  FETCH_VIOLATIONS_DETAILS_REQUESTED,
  FETCH_VIOLATIONS_DETAILS_RECEIVED,
  FETCH_4X_VIOLATIONS_REQUESTED,
  FETCH_4X_VIOLATIONS_RECEIVED,
  IGNORE_VIOLATIONS_REQUESTED,
  IGNORE_VIOLATIONS_RECEIVED,
  RESET_IGNORE_VIOLATION_STATUS,
  SITES_GEOMETRY_CACHED,
  RESET_STATUS,
  NETWORK_ERROR
} from '../constants/map';
import { postRequest, getEnforceRequest, constructSitesAPIUrl } from '../utils/api';
import moment from 'moment-timezone';

const initialState = {
  sites: [],
  sitesGroupsViolations: [],
  sitesStatus: null,
  violationsStatus: null,
  sitesGroupsViolationsStatus: null,
  violationDetails: {},
  cachedBounds: {},
  violationDetailsStatus: null,
  ignoreViolationStatus: null,
  bounds: null,
  spaceDetailsHeight: 0,
  networkError: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SITES_REQUESTED:
      return {
        ...state,
        sitesStatus: FETCH_SITES_REQUESTED,
        bounds: action.payload.bounds
      };
    case FETCH_SITES_RECEIVED:
      let { sites } = action.payload.sitesJson;
      let filteredSites = sites.filter(
        site => site.geojson && site.geojson.geometry
      );
      let addedViolationArray = filteredSites.map(site => {
        site.id = typeof site.id == "string" ? parseInt(site.id,10) : site.id ;
        return { ...site, violations: [] };
      });
      let cachedBoundsObj = {
        ...state.cachedBounds,
        [action.payload.bounds.join(', ')]: moment().format()
      };

      return {
        ...state,
        sites: state.sites.concat(addedViolationArray),
        sitesStatus: FETCH_SITES_RECEIVED,
        bounds: action.payload.bounds,
        cachedBounds: cachedBoundsObj
      };
    case SITES_GEOMETRY_CACHED:
      return {
        ...state,
        sitesStatus: FETCH_SITES_RECEIVED
      };
    case FETCH_VIOLATIONS_DETAILS_REQUESTED:
      return {
        ...state,
        violationDetailsStatus: FETCH_VIOLATIONS_DETAILS_REQUESTED
      };
    case FETCH_VIOLATIONS_DETAILS_RECEIVED:
      let { json, site_id } = action.payload;
      let violationDetails = state.violationDetails;
      violationDetails[site_id] = {
        session_id: json.session_id,
        session_start: json.session_start
      };
      return {
        ...state,
        violationDetails: violationDetails,
        violationDetailsStatus: FETCH_VIOLATIONS_DETAILS_RECEIVED
      };
    case IGNORE_VIOLATIONS_REQUESTED:
      return {
        ...state,
        ignoreViolationStatus: IGNORE_VIOLATIONS_REQUESTED
      };
    case IGNORE_VIOLATIONS_RECEIVED:
      return {
        ...state,
        ignoreViolationStatus: IGNORE_VIOLATIONS_RECEIVED
      };
    case FETCH_VIOLATIONS_REQUESTED:
      return {
        ...state,
        violatingStatus: FETCH_SITES_REQUESTED
      };
    case FETCH_VIOLATIONS_RECEIVED:
      let { violating_sites } = action.payload.sitesJson;
      if (!violating_sites) {
        violating_sites = [];
      }
      let stateSites = state.sites;
      stateSites.forEach(site => {
        let violatingSiteIndex = violating_sites.findIndex(violatingSite => {
          return violatingSite.site_id === site.id;
        });
        if (violatingSiteIndex !== -1) {
          site['violations'] = violating_sites[violatingSiteIndex].type
            ? violating_sites[violatingSiteIndex].type
            : [];
          site['since'] = violating_sites[violatingSiteIndex].since;
        } else {
          site['violations'] = [];
          site['since'] = {};
        }
      });
      return {
        ...state,
        sites: stateSites,
        violationsStatus: FETCH_VIOLATIONS_RECEIVED,
		  networkError: false
      };
    case FETCH_4X_VIOLATIONS_REQUESTED:
      return {
        ...state,
        sitesGroupsViolationsStatus: FETCH_4X_VIOLATIONS_REQUESTED
      };
    case FETCH_4X_VIOLATIONS_RECEIVED:
      let { groups_in_violation } = action.payload.sitesJson;
      return {
        ...state,
        sitesGroupsViolations: groups_in_violation ? groups_in_violation : [],
        sitesGroupsViolationsStatus: FETCH_4X_VIOLATIONS_RECEIVED,
		  networkError: false
      };
    case RESET_IGNORE_VIOLATION_STATUS:
      return {
        ...state,
        ignoreViolationStatus: null
      };
    case RESET_STATUS:
      return {
        ...state,
        violationsStatus: null,
        sitesGroupsViolationsStatus: null,
        sitesStatus: null
      };
	  case NETWORK_ERROR:
		  return {
			  ...state,
			  networkError: true
		  }
    default:
      return state;
  }
};

export function getSitesGeometry(bounds) {
  return (dispatch, getState) => {
    let { map } = getState();
    if (
      bounds.join(', ') in map.cachedBounds &&
      moment().diff(moment(map.cachedBounds[bounds.join(', ')]), 'hours') <= 24
    ) {
      dispatch(sitesRequested(bounds));
      dispatch(sitesGeometryCached());
    } else {
      const url = constructSitesAPIUrl(bounds);
      dispatch(sitesRequested(bounds));
      getEnforceRequest(url, getState().user).then(json => {
        dispatch(sitesReceived(json, bounds));
      });
    }
  };
}

export function getSitesViolations(bounds) {
  return (dispatch, getState) => {
    const url = constructSitesAPIUrl(bounds) + '&attributes=violation';
    dispatch(violationsRequested());
    getEnforceRequest(url, getState().user)
      .then(json => dispatch(violationsReceived(json)))
		.catch(error => {
			if (error.code === 'ERR_NETWORK') {
				dispatch(networkError());
			}
			console.log(error)
		});
  };
}

export function getSites4xViolations(bounds) {
  return (dispatch, getState) => {
    const url =
      constructSitesAPIUrl(bounds) + '&attributes=violation&zoom_level=4';
    dispatch(violations4xRequested());
    getEnforceRequest(url, getState().user)
      .then(json => dispatch(violations4xReceived(json)))
      .catch(error => {
		  if (error.code === 'ERR_NETWORK') {
			  dispatch(networkError());
		  }
		  console.log(error)
	  });
  };
}

export function getSiteViolationDetails(site_id) {
  return (dispatch, getState) => {
    dispatch(violationDetailsRequested());
    getEnforceRequest('violations?site_id=' + site_id, getState().user)
      .then(json => dispatch(violationDetailsReceived(json, site_id)))
      .catch(error => console.log(error));
  };
}

export function ignoreViolation(site_id, spaceDetails) {
  return (dispatch, getState) => {
    dispatch(ignoreViolationRequested());
    let violationDetails = getState().map.violationDetails[site_id];
    let params = {
      session_id: violationDetails.session_id,
      details: JSON.stringify({
        start_time: violationDetails.start_time,
        end_time: moment
          .tz(spaceDetails.geojson.properties['time-zone'])
          .format('YYYY-DD-MM HH:mm:ss'),
        violation_type: spaceDetails.violations.join(', '),
        comment: '',
        space_name: spaceDetails.name,
        action_taken: 'ignored'
      })
    };
    postRequest('tickets', params, getState().user)
      .then(json => dispatch(ignoreViolationReceived(json)))
      .catch(error => console.log(error));
    dispatch(ignoreViolationReceived());
  };
}

export function ignoreViolationRequested() {
  return { type: IGNORE_VIOLATIONS_REQUESTED };
}

export function sitesGeometryCached() {
  return { type: SITES_GEOMETRY_CACHED };
}

export function ignoreViolationReceived() {
  return {
    type: IGNORE_VIOLATIONS_RECEIVED
  };
}

export function violationDetailsRequested() {
  return { type: FETCH_VIOLATIONS_DETAILS_REQUESTED };
}

export function violationDetailsReceived(json, site_id) {
  return {
    type: FETCH_VIOLATIONS_DETAILS_RECEIVED,
    payload: { json, site_id }
  };
}

export function resetIgnoreViolationStatus() {
  return {
    type: RESET_IGNORE_VIOLATION_STATUS
  };
}

export function sitesRequested(bounds) {
  return { type: FETCH_SITES_REQUESTED, payload: { bounds } };
}

export function sitesReceived(sitesJson, bounds) {
  return { type: FETCH_SITES_RECEIVED, payload: { sitesJson, bounds } };
}

export function violationsRequested() {
  return { type: FETCH_VIOLATIONS_REQUESTED };
}

export function violationsReceived(sitesJson) {
  return { type: FETCH_VIOLATIONS_RECEIVED, payload: { sitesJson } };
}

export function violations4xRequested() {
  return { type: FETCH_4X_VIOLATIONS_REQUESTED };
}

export function violations4xReceived(sitesJson) {
  return { type: FETCH_4X_VIOLATIONS_RECEIVED, payload: { sitesJson } };
}

export function resetStatus() {
  return { type: RESET_STATUS };
}

export function networkError() {
	return { type: NETWORK_ERROR };
}
